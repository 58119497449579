import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

const initialState = {
    loginModal: {
        open: false,
        data: {}
    },
    reportJobModal: {
        open: false,
        data: {}
    },
    reportCompanyModal: {
        open: false,
        data: {}
    },
    menuModal: {
        open: false
    },
    reportJobSeekerModal: {
        open: false,
        data: {}
    },
    inviteJobSeekerModal: {
        open: false,
        data: {}
    },
    directMessageModal: {
        open: false,
        data: {}
    },
    buyResourcesModal: {
        open: false,
        data: {}
    },
    isChatOpen: {
        open: false,
        data: {}
    },
    reportConversationModal: {
        open: false,
        data: {}
    },
    initiateConversationModal: {
        open: false,
        data: {}
    },
    reportCancelSubscriptionModal: {
        open: false,
        data: {}
    },
    paymentMethodModal: {
        open: false,
        data: {}
    },
    feedbackToApplicantModal: {
        open: false,
        data: {}
    },
};

const slice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        setLoginModal(state, action) {
            state.loginModal = action.payload;
        },
        setReportJobModal(state, action) {
            state.reportJobModal = action.payload;
        },

        setReportCompanyModal(state, action) {
            state.reportCompanyModal = action.payload;
        },

        setMenuModal(state, action) {
            state.menuModal = action.payload;
        },

        setReportJobSeekerModal(state, action) {
            state.reportJobSeekerModal = action.payload;
        },

        setInviteJobSeekerModal(state, action) {
            state.inviteJobSeekerModal = action.payload;
        },

        setDirectMessageModal(state, action) {
            state.directMessageModal = action.payload;
        },

        setBuyResourcesModal(state, action) {
            state.buyResourcesModal = action.payload;
        },

        setIsChatOpen(state, action) {
            state.isChatOpen = action.payload;
        },

        setReportConversationModal(state, action) {
            state.reportConversationModal = action.payload;
        },

        setInitiateConversationModal(state, action) {
            state.initiateConversationModal = action.payload;
        },

        setReportCancelSubscriptionModal(state, action) {
            state.reportCancelSubscriptionModal = action.payload;
        },

        setPaymentMethodModal(state, action) {
            state.paymentMethodModal = action.payload;
        },

        setFeedbackToApplicantModal(state, action) {
            state.feedbackToApplicantModal = action.payload;
        },



    },
})


export function setLoginModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setLoginModal(payload));
    }
}
export function setBuyResourcesModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setBuyResourcesModal(payload));
    }
}

export function setReportJobModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setReportJobModal(payload));
    }
}

export function setReportCompanyModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setReportCompanyModal(payload));
    }
}

export function setReportJobSeekerModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setReportJobSeekerModal(payload));
    }
}

export function setInviteJobSeekerModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setInviteJobSeekerModal(payload));
    }
}

export function setDirectMessageModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setDirectMessageModal(payload));
    }
}

export function setMenuModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setMenuModal(payload));
    }
}

export function setIsChatOpenAction(payload) {
    return async () => {
        dispatch(slice.actions.setIsChatOpen(payload));
    }
}

export function setReportConversationModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setReportConversationModal(payload));
    }
}

export function setInitiateConversationModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setInitiateConversationModal(payload));
    }
}

export function setReportCancelSubscriptionModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setReportCancelSubscriptionModal(payload));
    }
}

export function setPaymentMethodModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setPaymentMethodModal(payload));
    }
}

export function setFeedbackToApplicantModalAction(payload) {
    return async () => {
        dispatch(slice.actions.setFeedbackToApplicantModal(payload));
    }
}



const appReducer = slice.reducer;
export default appReducer;
