import { Avatar, Backdrop, Box, Button, Skeleton, Typography } from "@mui/material";
import { stringAvatar } from "../pages/Applicant/ApplicantProfile/ProfileBar/profileBarHelperFuncs";
import { useSelector } from "react-redux";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import storage from "../util/firebase";
import { useSnackbar } from 'notistack';
import CircularProgressWithLabel from "./ui/CircularProgressWithLabel";
import { dispatch } from "../store/store";
import { useState } from "react";
import { updateUserProfileAction } from "../store/slices/userSlice";
import CustomModal from "./Modals/CustomModal";
import DarkButton from "./DarkButton";
import Dropzone from "react-dropzone";
import LightButton from "./LightButton";
import { ReactComponent as CameraIcon } from '../assets/icons/camera.svg';
import PlanStar from "./ui/PlanStar";
import { useGetQuotaUsage } from "../store/api/stripeApi";


const EditPhotoUrl = () => {
    const [open, setOpen] = useState(false);

    const { loading, data } = useSelector(state => state?.user);


    const { enqueueSnackbar } = useSnackbar()

    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleDropZone = (acceptedFiles) => {
        const file = acceptedFiles[0];

        // only allow image files
        if (!file.type.includes('image')) {
            enqueueSnackbar('Only image files are allowed!', { variant: 'error' });
            return;
        }


        if (file.size > 10242880) {
            enqueueSnackbar('Profile photo size limit is 10MB!', { variant: 'error' });
            return;
        }

        setOpen(false);


        setShowProgressBar(true);
        const fileName = new Date().getTime() + '-' + file.name;

        const storageRef = ref(storage, `/uploads/resume/${fileName}`);

        const metaData = {
            contentType: file.type
        }

        const uploadTask = uploadBytesResumable(storageRef, file, metaData);
        uploadTask.on("state_changed",
            snapshot => {
                let uploaded = Math.floor(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 90
                );

                setProgress(uploaded);
            },
            error => {
                console.log(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref)
                    .then(url => {
                        // update the url in the database
                        dispatch(updateUserProfileAction({ photo: { url, fileName } })).then(() => {
                            enqueueSnackbar('Profile Updated!', { variant: 'success' });
                            setProgress(100);
                            setShowProgressBar(false);
                        }).catch(() => {
                            enqueueSnackbar('Something went wrong!', { variant: 'error' });
                            setShowProgressBar(false);
                        }).finally(async () => {
                            // set progress to zero after 1.5 seconds to avoid abrupt progress bar closing
                            await new Promise(resolve => setTimeout(resolve, 1500));
                            setProgress(0);
                            // delete the previous photo
                            if (data?.photo?.fileName) {
                                deleteObject(ref(storage, `/uploads/resume/${data?.photo?.fileName}`)).then(() => {
                                    console.log('File has been removed.');
                                }).catch((error) => {
                                    console.log(error);
                                });
                            }

                        })
                    });
            }
        )

    }

    const removePhotoHandler = () => {
        setOpen(false);
        dispatch(updateUserProfileAction({ photo: { url: '', fileName: '' } })).then(() => {
            enqueueSnackbar('Profile photo removed!', { variant: 'info' });
        }).catch(() => {
            enqueueSnackbar('Something went wrong!', { variant: 'error' });
        }).finally(() => {
            // delete the previous photo
            if (data?.photo?.fileName) {
                deleteObject(ref(storage, `/uploads/resume/${data?.photo?.fileName}`)).then(() => {
                    console.log('File has been removed 2.');
                }).catch((error) => {
                    console.log(error);
                });
            }
        })
    }




    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgressBar}
            >
                <CircularProgressWithLabel value={progress} />

            </Backdrop>
            <CustomModal
                paperStyles={{
                    maxWidth: '850px',
                    // height: { xs: '100%', md: 'auto' },
                }}
                rounded
                open={open}
                setOpen={setOpen}
            >
                <Typography
                    sx={{
                        color: '#1D1D1D',
                        fontSize: '1.75rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        mt: 2
                    }}
                    align="center"
                >
                    Profile Photo
                </Typography>
                <Typography
                    sx={{
                        color: '#555',
                        fontSize: '0.875rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        mt: 1,
                        mb: 7
                    }}
                    align="center"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.  consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                </Typography>

                {
                    data?.photo?.url && (
                        <Box className="centered" sx={{ mb: 3 }}>
                            <Avatar
                                src={data?.photo?.url}

                                {...stringAvatar({
                                    str: data?.name || 'Un Known',
                                    width: '100px',
                                    height: '100px',
                                })}
                            />
                        </Box>
                    )
                }


                <Box className="centered">
                    <Box sx={{ display: 'flex', gap: 1.5, flexDirection: { xs: 'column', md: 'row' } }}>
                        {
                            (data?.photo?.url) && <LightButton
                                sx={{
                                    borderRadius: '0.375rem',
                                    background: '#F6F6F6',
                                    fontWeight: 500,
                                    color: '#1D1D1D'
                                }}
                                onClick={removePhotoHandler}
                            >
                                Delete photo
                            </LightButton>
                        }

                        <Dropzone onDrop={handleDropZone}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <DarkButton >
                                        {
                                            (data?.photo?.url) ? 'Change photo' : 'Upload photo'
                                        }
                                    </DarkButton>
                                </div>
                            )}
                        </Dropzone>
                    </Box>

                </Box>

                {!(data?.photo?.url) && <Typography
                    sx={{
                        color: '#555',
                        fontSize: '0.875rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        mt: 1
                    }}
                    align="center"
                >
                    10 Mb max size
                </Typography>}




                <Typography
                    sx={{
                        color: '#555',
                        fontSize: '0.875rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        mt: 7
                    }}
                    align="center"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                </Typography>


            </CustomModal>
            <Box className="centered" sx={{ position: 'relative' }}>

                {
                    loading ? <Skeleton variant="circular" width={100} height={100} />
                        :
                        <Box
                            title="Change Photo"
                            onClick={() => setOpen(true)}
                            sx={{
                                borderRadius: '50%', cursor: 'pointer',
                                '&:hover': {
                                    '& .cameraIcon': { display: 'block' },
                                    '& .MuiAvatar-root': { filter: 'brightness(0.7)' },
                                },
                                width: '100px', height: '100px'
                            }}>
                            <Box className="cameraIcon" sx={{ display: 'none', zIndex: 10, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                                <CameraIcon />
                            </Box>

                            <Avatar

                                src={data?.photo?.url}
                                {...stringAvatar({
                                    str: data?.name || 'Un Known',
                                    width: '100px',
                                    height: '100px',
                                })}
                            />
                        </Box>
                }

                <Box sx={{ position: 'absolute', left: '50%', bottom: -8, transform: 'translateX(-50%)' }}>
                    <RenderPlanState />
                </Box>
            </Box>

        </>
    );
}

export default EditPhotoUrl;


const RenderPlanState = () => {
    const { isLoading, data } = useGetQuotaUsage();


    return isLoading ?
        // <Skeleton animation={false} variant="circular" width={'1.5rem'} height={'1.5rem'} />
        null
        :
        <PlanStar
            variant={data?.planType}
        />
}
