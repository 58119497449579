import { Box } from "@mui/material";


// THIS COMPONENT IS USED AFTER LAST ELEMENT IN A PAGE TO ADD BOTTOM PADDING BEFORE 
// THE FOOTER. 

const BottomPadder = () => {
    return (<Box sx={{ pb: 6, height: '0.2px' }} />);
}

export default BottomPadder;
