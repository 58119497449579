import { Box, Button } from "@mui/material";
import { StylesTopNavBarButtonItems } from "../../style/styles";
import StyledBadge from "../ui/StyledBadge";
import { useGetConversations } from "../../store/api/conversationApi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import pusherClient from "../../config/pusherClient";
import { useQueryClient } from "@tanstack/react-query";


// ? Some Information Regarding how ConversationsList Updates
// ? when there is need to update the conversations Lists, we will use the pusherClient to listen to the channel 'conversations'
// ? from backend, we send the updated conversations list to the channel 'conversations' and the pusherClient will listen to it
// ? then we update the query client with the new data, and the conversations list will be updated automatically
// ? also we have added this listenr here in topnavbar but it will be used to update the conversations list in the messages page also
// ? so these two are linked

const CountMessagesNavBarItem = () => {
    const queryClient = useQueryClient();
    const { data } = useGetConversations();
    const user = useSelector(state => state?.user?.data);

    let unSeenMessagesCount = data?.reduce((acc, conversation) => {
        return acc + conversation?.unSeenMessagesCount;
    }, 0);

    useEffect(() => {
        if (user?._id) {

            let channel = pusherClient.subscribe('conversations');
            channel.bind(user?._id, function (data) {
                queryClient.setQueryData(['conversation'], (prev) => {
                    return data;
                });
            });
        }


        return () => {
            if (user?._id) {
                pusherClient.unsubscribe('conversations')
            }
        }
    }, [user?._id]);





    return (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Link to="/messages" className="reset-anchor-styling">
                <StyledBadge badgeContent={unSeenMessagesCount} color="error">
                    <Button
                        sx={{
                            ...StylesTopNavBarButtonItems,
                            py: 0
                        }}>
                        Messages
                    </Button>
                </StyledBadge>
            </Link>
        </Box>
    );
}

export default CountMessagesNavBarItem;