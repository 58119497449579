import ScrollToTop from './components/ScrollToTop';
import { AuthContext } from './context/AuthContext';
import { useAuth } from './hooks/AuthHook';
import RootRouter from './routes/rootRouter';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  const { token, userType, userId, login, logout } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userType: userType,
        userId: userId,
        login: login,
        logout: logout
      }}
    >
      <ScrollToTop>
        <HelmetProvider>
          <RootRouter />
        </HelmetProvider>
      </ScrollToTop>
    </AuthContext.Provider>
  );
};

export default App;