import { IconButton } from "@mui/material";
import { useDeleteNotificationById } from "../../store/api/notificationApi";
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useState } from "react";

const DeleteNotification = ({ notificationId }) => {
    const [delAction, setDelAction] = useState(false);
    const { mutateAsync } = useDeleteNotificationById();

    return (
        <IconButton
            title="Delete notification"
            disabled={delAction}
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                mutateAsync(notificationId);
                setDelAction(true);
            }}
            sx={{ p: 0.5, color: '#1C1B1F', cursor: 'pointer', '&:hover': { opacity: 0.6 } }}
        >
            <CloseIcon sx={{ fontSize: '1rem', }} />
        </IconButton>
    );
}

export default DeleteNotification;

DeleteNotification.prototype = {
    notificationId: PropTypes.string.isRequired
}
