import { Box } from "@mui/material";
import TopNavBar from "../components/TopNavBar/TopNavBar";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
    return <>
        <TopNavBar />
        <Box component="main">
            <Outlet />
        </Box>
        <Footer />

    </>;
}

export default MainLayout;


export const MainLayoutNonReactRouterDOM = ({ children }) => {
    return <>
        <TopNavBar />
        <Box component="main">
            {children}
        </Box>

        <Footer />

    </>;
}