import { lazy } from 'react';
import Loadable from './loadable';
import MainLayout from '../layouts/MainLayout';
import ProtectNonLoginRoutes from './protected/ProtectNonLoginRoutes';

const LoginSuccess = Loadable(lazy(() => import('../pages/LoginSuccess')));
const LoginFailed = Loadable(lazy(() => import('../pages/LoginFailed')));
const PageForgotPassword = Loadable(lazy(() => import('../pages/Auth/PageForgotPassword')));
const PageResetPassword = Loadable(lazy(() => import('../pages/Auth/PageResetPassword')));
const PageLogin = Loadable(lazy(() => import('../pages/Auth/PageLogin')));
const PageRegister = Loadable(lazy(() => import('../pages/Auth/PageRegister')));



const AuthRoutes = {
    path: '/',
    element: (
        <ProtectNonLoginRoutes>
            <MainLayout />
        </ProtectNonLoginRoutes>
    ),
    children: [
        {
            path: '/loginSuccess',
            element: <LoginSuccess />
        },
        {
            path: '/loginFailed',
            element: <LoginFailed />
        },
        {
            path: '/auth/forgot-password',
            element: <PageForgotPassword />
        },
        {
            path: '/auth/reset-password',
            element: <PageResetPassword />
        },
        {
            path: '/auth/login',
            element: <PageLogin />
        },
        {
            path: '/auth/register',
            element: <PageRegister />
        }

    ]
};

export default AuthRoutes;
