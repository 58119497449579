import customAxios from "../../util/axios";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";

export function useGetStripeConfig() {
  const queryFn = async () => {
    const response = await customAxios({
      method: "get",
      url: `/api/stripe/config`,
    });
    return response.data;
  };

  return useQuery({
    queryKey: ["stripeConfig"],
    queryFn,

    refetchOnWindowFocus: false,
  });
}

export function useCreateSubscription() {
  const mutationFn = async (data) => {
    const response = await customAxios({
      method: "post",
      url: `/api/stripe/create-subscription`,
      data: JSON.stringify(data),
    });
    return response.data;
  };

  return useMutation({
    mutationFn,
  });
}
export function useVerifyCoupon() {
  const mutationFn = async (data) => {
    const response = await customAxios({
      method: "post",
      url: `/api/stripe/verify-coupon`,
      data: JSON.stringify(data),
    });
    return response.data;
  };

  return useMutation({
    mutationFn,
  });
}
export function useGetApplicantPlans() {
  return useQuery({
    queryKey: ["applicantPlans"],
    queryFn: async () => {
      const response = await customAxios({
        method: "get",
        url: `/api/stripe/plans/applicant`,
      });
      return response.data;
    },

    refetchOnWindowFocus: false,
  });
}

export function useGetActiveSubscription() {
  return useQuery({
    queryKey: ["getActiveSubscription"],
    queryFn: async () => {
      const response = await customAxios({
        method: "get",
        url: `/api/stripe/getActiveSubscription`,
      });
      return response.data;
    },
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: 1000 * 60 * 60 * 2,
  });
}

export function useCancelSubscription() {
  const queryClient = useQueryClient();

  const mutationFn = async (data) => {
    const response = await customAxios({
      method: "delete",
      url: `/api/stripe/cancelSubscription`,
      data: JSON.stringify(data),
    });
    return response.data;
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries("getActiveSubscription");
      queryClient.invalidateQueries("applicantPlans");
      queryClient.invalidateQueries("immigrationFirmPlans");
      queryClient.invalidateQueries("getQuotaUsage");
    },
  });
}

export function useGetBillingDetails() {
  return useQuery({
    queryKey: ["getBillingDetails"],
    queryFn: async () => {
      const response = await customAxios({
        method: "get",
        url: `/api/stripe/getBillingDetails`,
      });
      return response.data;
    },
    refetchOnWindowFocus: false,
    retry: 0,
  });
}

export function useGetImmigrationFirmPlans() {
  return useQuery({
    queryKey: ["immigrationFirmPlans"],
    queryFn: async () => {
      const response = await customAxios({
        method: "get",
        url: `/api/stripe/plans/immigration-firm`,
      });
      return response.data;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetQuotaUsage() {
  return useQuery({
    queryKey: ["getQuotaUsage"],
    queryFn: async () => {
      const response = await customAxios({
        method: "get",
        url: `/api/stripe/quota-usage`,
      });
      return response.data;
    },
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: 1000 * 60 * 5,
  });
}

export function useDownloadPortfolio({ applicantId }) {
  return useQuery({
    queryKey: ["downloadPortfolio"],
    queryFn: async () => {
      const response = await customAxios({
        method: "get",
        url: `/api/download/applicant-portoflio/${applicantId}`,
        responseType: "blob",
      });

      return response.data;
    },
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: false,
  });
}

export function useGetDefaultPaymentMethod() {
  return useQuery({
    queryKey: ["getDefaultPaymentMethod"],
    queryFn: async () => {
      const response = await customAxios({
        method: "get",
        url: `/api/stripe/defaultPaymentMethod`,
      });
      return response.data;
    },
    refetchOnWindowFocus: false,
    retry: 0,
  });
}

export function useRemoveDefaultPaymentMethod() {
  const queryClient = useQueryClient();

  const mutationFn = async (data) => {
    const response = await customAxios({
      method: "delete",
      url: `/api/stripe/removeDefaultPaymentMethod`,
      data: JSON.stringify(data),
    });
    return response.data;
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries("getDefaultPaymentMethod");
    },
  });
}

export function useGetCreateSetupIntent() {
  return useQuery({
    queryKey: ["getCreateSetupIntent"],
    queryFn: async () => {
      const response = await customAxios({
        method: "get",
        url: `/api/stripe/create-setup-intent`,
      });
      return response.data;
    },
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: false,
  });
}

export function useSavePaymentMethodBySetupintent() {
  const queryClient = useQueryClient();

  const mutationFn = async (data) => {
    const response = await customAxios({
      method: "post",
      url: `/api/stripe/save-payment-method`,
      data: JSON.stringify(data),
    });
    return response.data;
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries("getDefaultPaymentMethod");
    },
  });
}

export function usePurchaseProductsWithDefaultPaymentMethod() {
  // const queryClient = useQueryClient();

  const mutationFn = async (data) => {
    const response = await customAxios({
      method: "post",
      url: `/api/stripe/purchase-products-with-default-payment`,
      data: JSON.stringify(data),
    });
    return response.data;
  };

  return useMutation({
    mutationFn,
    // onSuccess: () => {
    //     queryClient.invalidateQueries('getQuotaUsage');
    // }
  });
}

export function usePurchaseProductsWithNoPaymentMethod() {
  const mutationFn = async (data) => {
    const response = await customAxios({
      method: "post",
      url: `/api/stripe/purchase-products-no-payment-method-added`,
      data: JSON.stringify(data),
    });
    return response.data;
  };

  return useMutation({
    mutationFn,
  });
}

export const useSendOtpForDefaultPayment = () =>
  useMutation({
    mutationFn: async () => {
      const response = await customAxios({
        method: "post",
        url: `/api/stripe/send-otp-for-default-payment`,
      });
      return response.data;
    },
  });
