import { Box, Button, ClickAwayListener, Popper } from "@mui/material";
import { ReactComponent as NotificationIcon } from "../../assets/icons/clock.svg";
import { useEffect, useState } from "react";
import { StyleNotificationsPopper } from "../../style/styles";
import { useGetCountNonReadNotifications } from "../../store/api/notificationApi";
import pusherClient from "../../config/pusherClient";
import StyledBadge from "../ui/StyledBadge";
import { useSelector } from "react-redux";
import RenderNotifications from "./RenderNotification";
import { useQueryClient } from '@tanstack/react-query';

const Notification = () => {
    const queryClient = useQueryClient();
    const user = useSelector(state => state?.user?.data);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => { setAnchorEl(anchorEl ? null : event.currentTarget); };
    const handleCloseNotification = () => { setAnchorEl(null); };
    const { data, isSuccess } = useGetCountNonReadNotifications();

    const [unSeenNotifications, setUnSeenNotifications] = useState(0);


    useEffect(() => {
        if (isSuccess) { setUnSeenNotifications(Number(data?.totalDocuments)); }
    }, [data, isSuccess])



    useEffect(() => {
        if (user?._id) {
            let channel = pusherClient.subscribe('notification');
            channel.bind(user?._id, function (data) {
                queryClient.invalidateQueries('notification');
                queryClient.invalidateQueries('countNotification');
            });
        }


        return () => {
            pusherClient.unsubscribe('notification')
        }
    }, []);


    return (
        <>
            <Button sx={{ '&:hover': { opacity: 0.7 } }} aria-describedby={Boolean(anchorEl) ? 'notifications-popper' : undefined} onClick={handleClick} >
                <StyledBadge badgeContent={unSeenNotifications} color="error">
                    <NotificationIcon />
                </StyledBadge>
            </Button>

            <Popper sx={StyleNotificationsPopper} id={Boolean(anchorEl) ? 'notifications-popper' : undefined} open={Boolean(anchorEl)} anchorEl={anchorEl}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                    <Box>
                        <RenderNotifications handleCloseNotification={handleCloseNotification} />
                    </Box>
                </ClickAwayListener>
            </Popper>
        </>
    );
}

export default Notification;
