import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const CustomHelmet = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title} - Hiremai</title>
            {
                description && <meta name="description" content={description} />
            }
        </Helmet>
    );
};

export default CustomHelmet;

CustomHelmet.prototype = {
    title: PropTypes.string,
    description: PropTypes.string,
}

CustomHelmet.defaultProps = {
    title: '',
}