import { Box } from '@mui/material'
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { StyleInput } from '../../style/styles';
import Label from './Label';

const InputField = (props) => {
    const { label, labelVariant, textFieldProps } = props;

    const id = uuidv4();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {
                label && <Box sx={{ mb: '0.25rem' }}>
                    <Label id={id} labelVariant={labelVariant}>{label}</Label>
                </Box>
            }
            <input
                id={id}
                className='general-input'
                style={StyleInput}
                type="text"
                {...textFieldProps}
            />
        </Box>
    )
}

InputField.propTypes = {
    label: PropTypes.string,
    labelVariant: PropTypes.oneOf(['large', 'small', 'medium']),
    textFieldProps: PropTypes.object,
}

export default InputField;
