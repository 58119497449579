import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

const initialState = {
    items: [],
};

// ! CART - RULES
// ? Cart cannot have both subscription and products at a time
// * Cart can have only one subscription at a time
// * Cart can have multiple products at a time

const slice = createSlice({
    name: "cart",
    initialState: initialState,
    reducers: {
        addSubscriptionToCartSlice(state, action) {
            state.items = [action.payload];
        },

        removeItemFromCartSlice(state, action) {
            // check if an item with same priceId exist and remove it
            const priceId = action.payload;
            const index = state.items.findIndex(
                (item) => item.priceId === priceId
            );
            if (index !== -1) {
                state.items.splice(index, 1);
            }
        },

        clearCartSlice(state, action) {
            state.items = [];
        },

        addProductToCartSlice(state, action) {
            // if there is any item with type==='subscripton' in cart then remove it
            const index = state.items.findIndex(
                (item) => item.type === "subscription"
            );
            if (index !== -1) {
                state.items.splice(index, 1);
            }

            // add the new item to cart
            // dont push the item if it already exist
            const productId = action.payload.productId;
            const index2 = state.items.findIndex(
                (item) => item.productId === productId
            );

            if (index2 === -1) {
                state.items.push(action.payload);
            }
        },

        removeProductFromCartSlice(state, action) {
            // check if an item with same priceId exist and remove it
            const productId = action.payload;
            const index = state.items.findIndex(
                (item) => item.productId === productId
            );
            if (index !== -1) {
                state.items.splice(index, 1);
            }
        },
    },
});

export function addSubscriptionToCart(payload) {
    return async () => {
        dispatch(slice.actions.addSubscriptionToCartSlice(payload));
    };
}

export function removeItemFromCart(payload) {
    return async () => {
        dispatch(slice.actions.removeItemFromCartSlice(payload));
    };
}

export function clearCartAction() {
    return async () => {
        dispatch(slice.actions.clearCartSlice());
    };
}

export function addProductToCartAction(payload) {
    return async () => {
        dispatch(slice.actions.addProductToCartSlice(payload));
    };
}

export function removeProductFromCartAction(payload) {
    return async () => {
        dispatch(slice.actions.removeProductFromCartSlice(payload));
    };
}

const cartReducer = slice.reducer;
export default cartReducer;
