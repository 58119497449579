import { grey } from '@mui/material/colors';

export const StylesGreyBtn = {
    paddingLeft: '1.25rem',
    paddingRight: '1.25rem',
    color: '#1D1D1D',
    background: '#eee',
    borderRadius: '0.375rem',
    '&:hover': {
        background: '#ddd',
    }
};

export const StyleInput = {
    outline: 'none',
    borderRadius: '0.375rem',
    backgroundColor: '#F6F6F6',
    border: '1px solid',
    borderColor: 'transparent',
    fontSize: '0.75rem',
    width: '100%',
    color: '#555',
    padding: '10px 12px',
}

export const StyleInputCountrySearch = {
    p: 0,
    border: 'none', outline: 'none', width: '100%', backgroundColor: 'white', color: '#1D1D1D', fontSize: '0.875rem', fontWeight: 400
}


export const StylesChips = {
    backgroundColor: '#1D1D1D',
    color: 'white',
    cursor: 'default',
    p: 2,
    py: 2.5,
    borderRadius: '2.0625rem'
}


export const StylesChipsLight = {
    border: ' 1px solid #EEE',
    backgroundColor: '#FFF',
    color: '#555',
    cursor: 'default',
    p: 2,
    py: 2.5,
    borderRadius: '2.0625rem'
}

export const StylesChipsLightMini = {
    backgroundColor: '#FFF',
    color: '#49454F',
    fontWeight: 500,
    fontSize: '0.75rem',
    padding: '0.375rem 0.5rem',
    cursor: 'default',
    // p: 2,
    // py: 2.5,
    border: ' 1px solid #79747E',
    borderRadius: '2.125rem'

}



export const StylesPhoneNumber = {
    '&:hover': {

        '& p': {
            textDecoration: 'underline'
        }
    },
    cursor: 'pointer',
    color: '#555',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 600
}


export const StylesMenuItems = { color: '#555', fontSize: '0.875rem', fontWeight: 400 };

export const StylesHeading2 = {
    color: "#1D1D1D",
    fontSize: "1.25rem",
    fontWeight: 600,
};

export const StylesRadio = {
    color: grey[800],
    '&.Mui-checked': {
        color: grey[900],
    }
};

export const LabelStylesGen = (labelVariant) => ({
    color: '#1D1D1D',
    fontFeatureSettings: 'salt',
    fontSize: labelVariant === 'large' ? '1rem' : labelVariant === 'medium' ? '1rem' : '0.875rem',
    fontStyle: 'normal',
    fontWeight: (labelVariant === 'large' || labelVariant === 'medium') ? 500 : 400,
    marginBottom: '0.3rem',
});


export const StylesDarkCheckbox = {
    color: '#1D1D1D',
    '&.Mui-checked': {
        color: '#1D1D1D',
    },
    '&:hover': {
        backgroundColor: '#f6f6f6'
    }
}

export const StylesCustomSrollMenuNavBtn = {

    // boxShadow: 'inset -4px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    zIndex: 100, display: 'flex', alignItems: 'center', position: 'absolute', height: '100%',
    // width: { xs: 'auto', md: '3rem' },
    width: '2px',
    backgroundColor: 'white',

    transform: { xs: 'scale(0.7)', md: 'scale(1)' },
}

export const StylesCustomScrollMenuArrow = {
    transition: 'all 200ms ease', '&:hover': { opacity: 0.75 }, cursor: 'pointer', borderRadius: '50%',
    padding: { xs: 1, md: 1.5 },
    backgroundColor: '#555',
    '&:active': {
        transform: 'scale(0.9)'
    },
}

export const StylesDropdownFilterjobsListItem = {
    color: '#323C3E',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '0.70313rem',
    width: 205,
    py: 1.5,

}

export const StylesCountTextarea = ({ borderVariant }) => ({
    '.MuiOutlinedInput-notchedOutline': {
        // borderColor: '#555 !important',
        borderColor: borderVariant === 'dark' ? '#555 !important' : '#eee !important',
        borderRadius: '0 !important',
        fontSize: '0.875rem !important',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1 !important',
        outline: 'none',
        backgroundColor: 'transparent !important'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {

        borderColor: borderVariant === 'dark' ? '#555 !important' : '#eee !important',
    },
    '& .MuiOutlinedInput-root': {
        fontSize: '0.875rem !important',
    },
})

export const StylesCountTextareaNumb = {
    position: 'absolute',
    bottom: 4,
    right: 10,
    color: '#555',
    fontSize: '0.875rem',
    fontWeight: 400
}


export const StyleRadioLabel = {
    color: '#555',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.25rem',
}

export const StylesViewJobApplicantsCard = {
    border: '2px solid #FFF',
    '&:hover': {
        backgroundColor: '#F6F6F6',
    },
    transition: 'all 0.2s ease',
    cursor: 'pointer'
}

export const StylesMenuDialogPaperProps = {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 0,
    p: 6,
    py: 8,
    width: '100%',
    maxWidth: '22rem',
    borderRadius: 0,
    overflow: 'auto'
};

export const StylesMenubarDropdownMenuItems = { borderLeft: '0.25rem solid transparent', color: '#1D1D1D', fontSize: '0.75rem', fontWeight: 500 }


// ? following property is being at various places, don't change any property
export const StylesShadowedPaper = {
    borderRadius: '0.625rem',
    border: '1px solid #EEE',
    background: '#FFF',
    boxShadow: '0px 22px 34px -10px rgba(0, 0, 0, 0.15)',
    p: { xs: 4, md: 6, lg: 8 },
    width: '30rem',
    maxWidth: '100%',
    mx: 2
}


export const StylesProfileBarLeftSec = {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    flexDirection: { xs: 'column', lg: 'row' },
    borderRight: { xs: 'none', md: '2px solid #EEE' },
    mr: { xs: 0, md: 2 },
    borderBottom: { xs: '2px solid #EEE', md: 'none' },
    pb: { xs: 3, md: 0 },
}

export const StyleTextAnswerAccordion = {
    color: '#1D1D1D',
    fontWeight: 400,
    fontSize: '0.75rem'
}

export const StyleTextQuestionAccordion = {
    color: '#1D1D1D',
    fontWeight: 600,
    fontSize: '1rem'
}

export const StyleNotificationsPopper = {
    zIndex: 200,
    width: '25rem',
    maxWidth: '100%',
    borderRadius: '0rem 0rem 0.5rem 0.5rem',
    background: '#FFF',
    overflow: 'hidden',
    boxShadow: '0px 22px 34px -10px rgba(0, 0, 0, 0.15)'
}


export const StylesPaddingXMessagesGrid = {
    px: { xs: 2, md: 4 },
};


export const StylesPaddingYMessagesGrid = {
    py: 2,
}

export const StylesTopNavBarButtonItems = { px: 0, '&:hover': { opacity: 0.8 }, textTransform: 'none', fontWeight: 500, fontSize: '0.875rem', color: '#fff' }

export const StylesPaperSearchTopNavBar = {
    width: '274px',
    overflow: 'hidden',
    borderRadius: '0rem 0rem 0.5rem 0.5rem',
    background: '#FFF',
    boxShadow: '0px 22px 34px -10px rgba(0, 0, 0, 0.15)',
    bgcolor: 'background.paper',
    pb: 2
};


export const StylesUserTypeCard = {
    width: '11rem',
    aspectRatio: '1/1',
    border: '1px solid #eee',
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    '&:hover': { backgroundColor: '#f1f1f1', },
    // '&:active': { transform: 'scale(0.95)', },
    cursor: 'pointer'
}

export const StylesFooterSlogan = {
    my: 2,
    color: "#FFF",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
}