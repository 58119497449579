import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import CustomCircularProgress from './ui/CustomCircularProgress';


const PageLoadingSpinner = ({ height }) => {

    return (
        <Box sx={{
            display: 'flex',
            height: height || '100vh',
            alignItems: 'center',
            justifyContent: 'center'

        }}>
            <CustomCircularProgress />
        </Box>
    );
};

PageLoadingSpinner.propTypes = {
    height: PropTypes.string
};

export default PageLoadingSpinner;

