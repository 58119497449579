import 'react-phone-number-input/style.css'
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form"
import './Phone.scss';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';
import { LabelStylesGen } from '../../../style/styles';


// USAGE EXAMPLE IS AT END OF THIS FILE

// FOLLOWING COMPONENT WORKS WITH REACT-HOOK-FORM ONLY

const Phone = (props) => {
    const { placeholder, name, control, label, labelVariant } = props;
    const id = uuidv4();


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <label
                style={LabelStylesGen(labelVariant)}
                htmlFor={id}
            >
                {label}
            </label>
            <PhoneInputWithCountry
                id={id}
                international
                placeholder={placeholder || 'Enter your phone number'}
                name={name}
                control={control}
                rules={{ required: true }}
            />


        </Box>


    )
}

Phone.propTypes = {
    label: PropTypes.string.isRequired,
    labelVariant: PropTypes.oneOf(['large']),
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
}

export default Phone;



{/* <Box>
    <Phone
        labelVariant={'large'}
        label="Phone Number"
        placeholder={'Enter your phone number'}
        control={control}
        name={'companyPhone'}

    />
    {errors.companyPhone && <HelperText>{errors.companyPhone.message}</HelperText>}
</Box> */}