import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { getUserToken } from '../../util/getUserToken';
import axios from 'axios';
import { getApplicantProfile } from './userSlice';

const initialState = {
    loading: true,
    updating: false,
    error: null,
    data: {}
};

const slice = createSlice({
    name: 'immigrationProfile',
    initialState: initialState,
    reducers: {
        setData(state, action) {
            state.data = action.payload;
        },

        setLoading(state, action) {
            state.loading = action.payload;
        },

        setUpdating(state, action) {
            state.updating = action.payload;
        },


        hasError(state, action) {
            state.error = action.payload;
        }
    },
})


export function getImmigrationProfileAction() {
    return async () => {
        try {
            let token = await getUserToken();
            let config = {
                method: 'get',
                url: '/api/applicant/immigration-profile',
                headers: {
                    'Authorization': `bearer ${token}`
                }
            };
            dispatch(slice.actions.setLoading(true));
            let response = await axios(config);


            dispatch(slice.actions.setLoading(false));

            if (response.status === 200) {
                dispatch(slice.actions.setData(response?.data));
            } else {
                dispatch(slice.actions.hasError(response?.data?.message));
            }


        } catch (error) {
            dispatch(slice.actions.hasError(error?.response?.data?.message));
        }
    };
}


export function updateImmigrationProfileAction(data) {


    return async () => {
        try {
            let token = await getUserToken();
            let config = {
                method: 'patch',
                url: '/api/applicant/immigration-profile',
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            dispatch(slice.actions.setUpdating(true));
            let response = await axios(config);

            dispatch(slice.actions.setUpdating(false));

            if (response.status === 200) {
                dispatch(slice.actions.setData(response?.data));
                // also refresh the applicant profile
                await dispatch(getApplicantProfile());
            }
            else {
                dispatch(slice.actions.hasError(response?.data?.message));
                return { success: false, error: response?.data?.message }
            }


            return { success: true }

        } catch (error) {
            dispatch(slice.actions.setUpdating(false));
            dispatch(slice.actions.hasError(error?.response?.data?.message));
            return { success: false, error: error?.response?.data?.message }
        }
    }
}


const immigrationProfileReducer = slice.reducer;
export default immigrationProfileReducer;
