import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = (props) => {

    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  


    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
};

export default ScrollToTop;