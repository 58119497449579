import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        fontSize: '0.6rem',
        minWidth: '18px',
        height: '18px',
    },
    '& .MuiBadge-colorError': {
        backgroundColor: '#B3261E'
    }
});

export default StyledBadge;
