import { lazy } from 'react';

import Loadable from './loadable';
import MainLayout from '../layouts/MainLayout';
import ProtectApplicantRoutes from './protected/ProtectApplicantRoutes';
import PageNotFound from '../pages/PageNotFound';


const PageApplicantProfile = Loadable(lazy(() => import('../pages/Applicant/ApplicantProfile/PageApplicantProfile')));
const PageImmigrationProfile = Loadable(lazy(() => import('../pages/Applicant/ImmigrationProfile/PageImmigrationProfile')));
const ApplicantPageHome = Loadable(lazy(() => import('../pages/Applicant/Home/Home')));
const PageSearchJobs = Loadable(lazy(() => import('../pages/Applicant/SearchJobs/PageSearchJobs')));
const PageViewJobDetailApplicant = Loadable(lazy(() => import('../pages/Applicant/ViewJobDetail/PageViewJobDetailApplicant')));
const PageVerifyAccount = Loadable(lazy(() => import('../pages/Auth/PageVerifyAccount')));
const PageManageAccount = Loadable(lazy(() => import('../pages/PageManageAccount')));
const PageApplicantFaqs = Loadable(lazy(() => import('../pages/Faqs/PageFaqs')));
const PageApplicantBlogs = Loadable(lazy(() => import('../pages/Blogs/PageBlogs/PageBlogs')));
const PageApplicantViewBlog = Loadable(lazy(() => import('../pages/Blogs/PageViewBlog/PageViewBlog')));
const PageApplicantResources = Loadable(lazy(() => import('../pages/Resources/PageResources/PageResources')));
const PageApplicantViewResource = Loadable(lazy(() => import('../pages/Resources/PageViewResource/PageViewResource')));
const PageApplicantSubscriptionPlans = Loadable(lazy(() => import('../pages/Applicant/SubscriptionPlans/PageApplicantSubscriptionPlans')));
const PageApplicantViewCompany = Loadable(lazy(() => import('../pages/Applicant/ViewCompany/PageApplicantViewCompany')));
const PageSearchCompanies = Loadable(lazy(() => import('../pages/Applicant/SearchCompanies/PageSearchCompanies')));
const PageSubscription = Loadable(lazy(() => import('../pages/Subscription/PageSubscription')));
const PageCart = Loadable(lazy(() => import('../pages/Cart/PageCart')));



const ApplicantRoutes = {
    path: '/',
    element: (
        <ProtectApplicantRoutes>
            <MainLayout />
        </ProtectApplicantRoutes>
    ),
    children: [
        {
            path: '/applicant',
            element: <ApplicantPageHome />
        },
        {
            path: '/applicant/search-jobs',
            element: <PageSearchJobs />
        },
        {
            path: '/applicant/profile',
            element: <PageApplicantProfile />
        },
        {
            path: '/applicant/immigration-profile',
            element: <PageImmigrationProfile />
        },
        {
            path: '/applicant/view-job/:id',
            element: <PageViewJobDetailApplicant />
        },
        {
            path: '/applicant/verifyAccount',
            element: <PageVerifyAccount />
        },
        {
            path: '/applicant/manage-account',
            element: <PageManageAccount />
        },
        {
            path: '/applicant/faqs',
            element: <PageApplicantFaqs />
        },
        {
            path: '/applicant/blogs',
            element: <PageApplicantBlogs />
        },
        {
            path: '/applicant/blogs/:id',
            element: <PageApplicantViewBlog />
        },
        {
            path: '/applicant/resources',
            element: <PageApplicantResources />
        },
        {
            path: '/applicant/resources/:id',
            element: <PageApplicantViewResource />
        },
        {
            path: '/applicant/subscription-plans',
            element: <PageApplicantSubscriptionPlans />
        },
        {
            path: '/applicant/search-companies',
            element: <PageSearchCompanies />
        },
        {
            path: `/applicant/view-company/:companyId`,
            element: <PageApplicantViewCompany />
        },
        {
            path: '/applicant/subscription',
            element: <PageSubscription />
        },
        {
            path: '/applicant/cart',
            element: <PageCart />
        },
        {
            path: '/*',
            element: <PageNotFound />
        },
    ]
};

export default ApplicantRoutes;
