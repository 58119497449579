import StarIcon from '@mui/icons-material/Star';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import ApartmentIcon from '@mui/icons-material/Apartment';

const colorMap = {
    free: 'black',
    silver: '#A1A6A9',
    gold: '#D8AC2F',
    platinum: '#516A7E',
    tbd: '#1d1d1d'
}

const StylesIcon = { fontSize: '1rem', color: 'white' };

const IconMap = {
    free: <StarIcon sx={StylesIcon} />,
    silver: <StarIcon sx={StylesIcon} />,
    gold: <StarIcon sx={StylesIcon} />,
    platinum: <StarIcon sx={StylesIcon} />,
    tbd: <ApartmentIcon sx={StylesIcon} />
}

const PlanStar = ({ variant }) => {
    return (
        <Box
            sx={{
                backgroundColor: colorMap[variant],
                borderRadius: '50%',
                width: '1.25rem',
                height: '1.25rem',
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}
        >
            {IconMap[variant]}
            {/* <StarIcon sx={{ fontSize: '1rem', color: 'white' }} /> */}
        </Box>
    );
}

export default PlanStar;

PlanStar.prototype = {
    variant: PropTypes.oneOf(['free', 'silver', 'gold', 'platinum', 'tbd'])
}

PlanStar.defaultProps = {
    variant: 'free'
}