import ErrorPage from "../../components/ErrorPage";
import { useSelector } from "react-redux";
import { dispatch } from "../../store/store";
import { getApplicantProfile } from "../../store/slices/userSlice";


const AccessDenied = () => (
    <ErrorPage showHomeButton={true} showBackButton={false} primaryText="Access Denied"
        secondaryText="Please login to access this page."
    />
);

// ! USAGE
// ? This Component protects the routes that are only accessible to logged in users.
// ? It was created to protects the pages that are used by both the applicant and the employer.
// ? For example, PageMessages.js. 
// ? Don't create separate routes for the applicant and the employer for the same page. 
// ? It will lead to increase the bundle size.

const ProtectLoginPages = ({ children }) => {
    const { loading } = useSelector(state => state?.user);
    if (loading) { dispatch(getApplicantProfile()); }


    const storedData = JSON.parse(localStorage.getItem('immigrationJobUserData'));

    return storedData ? children : <AccessDenied />;
}

export default ProtectLoginPages;