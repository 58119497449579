import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const LightButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    padding: '6px 20px',
    border: 'none',
    fontWeight: '500',
    backgroundColor: '#EEE',
    color: '#1D1D1D',
    borderRadius: '0.375rem',
    // transition: 'all 0.2s ease-in-out',
    // '&.Mui-disabled': {
    //     color: 'white',
    //     backgroundColor: '#d6d6d6',
    // },
    '&:hover': {
        backgroundColor: '#d6d6d6',
    },
    '&:active': {
        boxShadow: 'none',
        transform: 'scale(0.95)'
        // backgroundColor: '#000000',
    },
    '&:focus': {

    },
});

export default LightButton;