// USER FUL STYLE GENERATORS
// The following function is used to generate ellipsis styles for text after X number of lines
export const StyleGenEllipsis = (lines) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: lines,
    WebkitBoxOrient: "vertical",
});

