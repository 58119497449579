import { Box, Typography } from "@mui/material";
import { ReactComponent as ErrorIcon } from '../assets/icons/errorLg.svg';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import DarkButton from "./DarkButton";
import GoldenButton from "./GoldenButton";
import { useNavigate } from "react-router-dom";
import StarsIcon from '@mui/icons-material/Stars';

const Error = ({ icon, showBackButton, showHomeButton, primaryText, secondaryText, actionButton }) => {
    const navigate = useNavigate();


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {
                icon ? icon : <ErrorIcon />
            }


            <Typography sx={{ mt: 5, fontSize: '1.25rem', fontWeight: 600, color: '#1D1D1D' }} component={"h2"}>
                {primaryText}
            </Typography>
            <Typography sx={{ mt: 1, fontSize: '1rem', fontWeight: 400, color: '#1D1D1D' }}>
                {secondaryText}
            </Typography>
            <Box sx={{ mt: 5, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
                {
                    showBackButton && <DarkButton
                        onClick={() => navigate(-1)}
                        startIcon={<ChevronLeftIcon />}
                    >
                        Go Back
                    </DarkButton>
                }
                {
                    showHomeButton && <DarkButton
                        onClick={() => navigate('/')}
                        startIcon={<HomeIcon />}
                    >
                        Go Home
                    </DarkButton>
                }
                {
                    actionButton!=null && <GoldenButton
                        onClick={() => navigate(actionButton?.action)}
                        startIcon={<StarsIcon />}
                    >
                        {actionButton?.text}
                    </GoldenButton>
                }
            </Box>

        </Box>
    );
}

export default Error;

Error.prototype = {
    icon: PropTypes.element,
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    showBackButton: PropTypes.bool,
    showHomeButton: PropTypes.bool,
    actionButton: PropTypes.object,
}

Error.defaultProps = {
    primaryText: 'Something went wrong!',
    secondaryText: 'Please try again later.',
    showBackButton: true,
    showHomeButton: false,
    actionButton: null,
}
