import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./style/style.scss";
import "./style/customTailwind.scss";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { Grow } from "@mui/material";
import SnackbarCloseButton from "./components/ui/notistack.component";
import { persistor, store } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// @tanstack/react-query

const queryClient = new QueryClient();

const theme = createTheme({
    palette: {
        primary: {
            main: "#1d1d1d",
        },
    },

    typography: {
        fontFamily: [
            "Poppins",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
});

ReactDOM.createRoot(document.getElementById("root")).render(
    <BrowserRouter>
        <SnackbarProvider
            action={(snackbarKey) => (
                <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
            TransitionComponent={Grow}
            autoHideDuration={3000}
            maxSnack={3}
        >
            <PersistGate persistor={persistor}>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <QueryClientProvider client={queryClient}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <App />
                            </LocalizationProvider>
                            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                        </QueryClientProvider>
                    </ThemeProvider>
                </Provider>
            </PersistGate>
        </SnackbarProvider>
    </BrowserRouter>
);
