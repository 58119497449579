
export const getUserToken = async () => {
    let token = null;
    try {
        let storedData = JSON.parse(localStorage.getItem('immigrationJobUserData'));
        token = storedData?.token;

    } catch (error) {
        console.log(error);
    }
    return token;
};

export const getUserType = async () => {
    let userType = null;
    try {
        let storedData = JSON.parse(localStorage.getItem('immigrationJobUserData'));
        userType = storedData?.userType;
    } catch (error) {
        console.log(error);
    }
    return userType;
};

export const getUserTypeSync = () => {
    let userType = null;
    try {
        let storedData = JSON.parse(localStorage.getItem('immigrationJobUserData'));
        userType = storedData?.userType;
    } catch (error) {
        console.log(error);
    }
    return userType;
};

export const isLoggedInSync = () => {
    let isLoggedIn = false;
    try {
        let storedData = JSON.parse(localStorage.getItem('immigrationJobUserData'));
        isLoggedIn = storedData?.token ? true : false;
    } catch (error) {
        console.log(error);
    }
    return isLoggedIn;
}