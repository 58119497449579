import { Box, Skeleton, Typography } from "@mui/material";

const SkeletonNotification = () => (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', padding: '1.5rem', mt: 1.4, maxHeight: '85vh', overflow: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{ color: '#1d1d1d', fontSize: '1.2rem' }}>Notifications</Typography>
        </Box>

        {
            [0, 1, 2, 3]?.map(item => {
                return (

                    <Box key={item} sx={{ cursor: 'pointer', p: 1, alignItems: 'center', border: '0.8px solid #EEE', borderRadius: '0.6rem', display: 'flex', gap: '0.5rem' }}>
                        <Box>
                            <Skeleton variant="rounded" width="4rem" height="4rem" />
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Skeleton variant="text" width="100%" height="1.25rem" />
                            <Skeleton variant="text" width="90%" height="1.25rem" />
                            <Skeleton variant="text" width="80%" height="1.25rem" />
                        </Box>
                    </Box>
                )
            })
        }
    </Box>
);

export default SkeletonNotification;