import * as React from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { StylesMenubarDropdownMenuItems } from '../../style/styles';
import { NavLink } from 'react-router-dom';
import {
    usePopupState,
    bindHover,
    bindPopper,
} from 'material-ui-popup-state/hooks'
import { Box, Paper, Popper } from '@mui/material';



export default function RenderDropdownNavbar({ item }) {
    const { name, children } = item;
    const popupState = usePopupState({
        variant: 'popper',
        popupId: 'demoPopper',
    })


    // fix dropdown active menu, in case of 
    const fixActiveLink = (path) => {
        let pathBeforeQuestionMark = path.split('?')[0];
        if (pathBeforeQuestionMark && pathBeforeQuestionMark === '/applicant/search-jobs') {
            return false
        }
        return true;
    }

    return (
        <div>
            <Button sx={{ minWidth: '0px', px: 0, '&:hover': { opacity: 0.8 }, textTransform: 'none', fontWeight: 500, fontSize: '0.875rem', color: '#fff' }}
                id={name + '-dropdown-button'}
                aria-controls={(name + '-dropdown-menu')}
                aria-haspopup="true"
                aria-expanded={'true'}
                {...bindHover(popupState)}
            >
                {name}
            </Button>

            <Popper
                disablePortal
                // style={{ zIndex: 10000 }}
                // z index change of popper

                {...bindPopper(popupState)} placement="bottom">
                <Paper sx={{
                    backgroundColor: 'transparent',
                    boxShadow: '0px 22px 34px -10px rgba(0, 0, 0, 0.15)',
                    borderRadius: '0rem 0rem 0.5rem 0.5rem',
                    overflow: 'hidden',
                }}>

                    <Box sx={{

                        width: '10rem',
                        mt: 1.8,
                        backgroundColor: 'white',
                        padding: '25px 0px 25px 0px !important',
                    }}>
                        {
                            children.map((child) => (
                                <NavLink
                                    key={child.id}
                                    to={child.path}
                                    className='reset-anchor-styling'
                                >
                                    {({ isActive, isPending }) => (
                                        <MenuItem disableRipple sx={{
                                            ...StylesMenubarDropdownMenuItems,
                                            ...(isActive && fixActiveLink(child?.path) && { fontWeight: 600, borderLeft: '0.25rem solid #1d1d1d' }),
                                        }} onClick={() => {
                                            popupState.close();
                                        }}>
                                            {child.name}
                                        </MenuItem>
                                    )}

                                </NavLink>
                            ))
                        }

                    </Box>
                </Paper>
            </Popper>
        </div>
    )
}
