import { Avatar, Box } from "@mui/material";
import { ReactComponent as ToolIconSvg } from '../assets/icons/tools.svg'
import PropTypes from 'prop-types';

// i want to introduce a new size between small and mini, suggest me a name
const StylesDimensionsAvatarMap = {
    large: '5.5rem',
    medium: '4rem',
    small: '3.5rem',
    micro: '3rem',
    mini: '2.5rem'
}


const StylesIconMap = {
    large: '3rem',
    medium: '2rem',
    small: '1.5rem',
    micro: '1.25rem',
    mini: '1.25rem'
}

const StylesDimensionsAvatarWrapper = (size) => ({
    width: StylesDimensionsAvatarMap[size],
    height: StylesDimensionsAvatarMap[size],
})




const CompanyAvatar = ({ size, avatarUrl }) => (
    avatarUrl ?
        <Avatar src={avatarUrl} sx={{
            borderRadius: ' 0.25963rem',
            // set the image to cover the entire area of the avatar
            objectFit: 'contain',
            ...StylesDimensionsAvatarWrapper(size)
        }} />
        :
        (
            <Box
                sx={{
                    borderRadius: ' 0.25963rem', backgroundColor: '#EEE', aspectRatio: '1/1',
                    ...StylesDimensionsAvatarWrapper(size)
                }} className="centered">
                <ToolIconSvg style={{
                    width: StylesIconMap[size],
                    height: StylesIconMap[size],

                }} />
            </Box>
        )
);

export default CompanyAvatar;

CompanyAvatar.propTypes = {
    size: PropTypes.oneOf(['large', 'medium', 'small', 'micro', 'mini']),
    avatarUrl: PropTypes.string
}

CompanyAvatar.defaultProps = {
    size: 'large'
}