import { rootReducer } from "./rootReducer";
import { persistStore, persistReducer } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit'
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage,
    blacklist: ['user', 'immigrationProfile', 'updateImmigrationProfileAction', 'app']
}
const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
    // devTools: process.env.NODE_ENV !== 'production',
});

const { dispatch } = store;
export { dispatch };

export const persistor = persistStore(store);
