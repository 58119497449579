import PropTypes from 'prop-types';
import { LabelStylesGen } from '../../style/styles';

const Label = ({ id, children, labelVariant }) => {
    return <label
        style={LabelStylesGen(labelVariant)}
        htmlFor={id}
    >
        {children}
    </label>;
}

export default Label;

Label.propTypes = {
    id: PropTypes.string,
    labelVariant: PropTypes.oneOf(['large', 'small', 'medium'])
};