import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const DarkButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    padding: '6px 20px',
    border: 'none',
    fontWeight: '500',
    backgroundColor: '#1D1D1D',
    color: 'white',
    borderRadius: '0.375rem',
    '&.Mui-disabled': {
        color: 'white',
        backgroundColor: '#4a4a4a',
        opacity: '0.75',
    },
    '&:hover': {
        backgroundColor: '#4a4a4a',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#000000',
        transform: 'scale(0.95)'
    },
});

export default DarkButton;

export const StylesInvertDarkButton = {
    backgroundColor: 'white',
    color: '#1d1d1d',
    '&:hover': {
        backgroundColor: 'white',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: 'white',
        transform: 'scale(0.95)'
    },
};

export const StylesMiniButton = {
    fontSize: '0.675rem'
}