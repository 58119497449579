import { Box, Typography } from "@mui/material";
import CompanyAvatar from "../CompanyAvatar";
import { useDeleteAllNotifications, useGetNotifications, useMarkAllNotificationsAsRead, useUpdateNotificationReadStatusById } from "../../store/api/notificationApi";
import SkeletonNotification from "./SkeletonNotification";
import moment from "moment";
import LightButton from "../LightButton";
import { StyleGenEllipsis } from "../../style/StylesFunctions";
import DeleteNotification from "./DeleteNotification";
import { useNavigate } from "react-router-dom";

function formatDate(date) {
    try {
        let strDate = date.split(" ");
        let format = "";
        if (strDate[0] == "un" || strDate[0] == "a") {
            strDate[0] = "1";
            format = strDate[0] + strDate[1][0];
            return format
        }
        format = strDate[0] + strDate[1][0];
        return format
    } catch (error) {
        console.warn(error);
        return date
    }
}


const RenderNotifications = ({ handleCloseNotification }) => {
    const navigate = useNavigate();
    const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isError, isLoading } = useGetNotifications({ page: 1 });
    const markAllReadHookFunc = useMarkAllNotificationsAsRead();
    const deleteAllNotHookFunc = useDeleteAllNotifications();
    const markReadByIdHookFunc = useUpdateNotificationReadStatusById();



    let notificationsArr = data?.pages?.map((page) =>
        page?.data?.map((item, i) => {
            return {
                _id: item?._id,
                content: item?.content,
                isRead: item?.isRead,
                createdAt: item?.createdAt && (formatDate(moment(item?.createdAt).fromNow(true)) + " ago"),
                href: item?.href,
                avatarUrl: item?.avatarUrl
            }
        })
    )?.flat();



    const handleMarkAllAsRead = async () => {
        await markAllReadHookFunc.mutate();
        handleCloseNotification();
    }

    const handleDeleteAllNotifications = async () => {
        await deleteAllNotHookFunc.mutate();
    }

    const handlerNavAction = ({ notificationId, href, isRead }) => {
        if (href) {
            navigate(href);
            handleCloseNotification();
            if (!isRead) {
                markReadByIdHookFunc.mutate(notificationId);
            }
        }
    }



    if (isLoading) return <SkeletonNotification />


    return (
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', padding: '1.5rem', mt: 1.4, maxHeight: '85vh', overflow: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600, color: '#1d1d1d', fontSize: '1.2rem' }}>Notifications</Typography>
                {
                    notificationsArr.length > 0 && <Box sx={{ display: 'flex', gap: 1 }}>
                        <Typography onClick={handleMarkAllAsRead}
                            sx={{ color: '#1C1B1F', fontWeight: 600, fontSize: '0.725rem', cursor: 'pointer', '&:hover': { opacity: 0.6 } }}
                        >
                            Mark all as read
                        </Typography>
                        <Typography onClick={handleMarkAllAsRead}
                            sx={{ color: '#1C1B1F', fontWeight: 600, fontSize: '0.725rem' }}
                        >
                            |
                        </Typography>
                        <Typography onClick={handleDeleteAllNotifications}
                            sx={{ color: '#1C1B1F', fontWeight: 600, fontSize: '0.725rem', cursor: 'pointer', '&:hover': { opacity: 0.6 } }}
                        >
                            Clear All
                        </Typography>
                    </Box>
                }
            </Box>
            {
                notificationsArr?.map(item => {
                    return (

                        <Box key={item?._id} sx={{
                            backgroundColor: item?.isRead ? 'white' : '#FFFBFE',
                            p: 1.75, border: '0.8px solid #EEE', borderRadius: '0.6rem', display: 'flex', gap: 1,
                            ...(item?.href && {
                                cursor: 'pointer', '&:hover': { backgroundColor: '#eeeeee' }
                            })
                        }}
                            onClick={() => { handlerNavAction({ notificationId: item?._id, href: item?.href, isRead: item?.isRead }) }}
                        >
                            <CompanyAvatar
                                size="micro"
                                avatarUrl={item?.avatarUrl}
                            />
                            <Box sx={{ width: '100%', gap: 1.6, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography sx={{
                                    ...(StyleGenEllipsis(2)), color: '#555', fontSize: '0.75rem',
                                    fontWeight: item?.isRead ? 400 : 600
                                }}>{item.content}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <Box><DeleteNotification notificationId={item._id} /> </Box>
                                <Typography noWrap align="right" sx={{ color: '#555', fontSize: '0.6rem' }}>{item.createdAt}</Typography>
                            </Box>
                        </Box>
                    )
                })
            }
            {
                hasNextPage &&
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1.5 }}>
                    <LightButton disabled={isFetchingNextPage} onClick={() => fetchNextPage()} size="small">Load More</LightButton>
                </Box>
            }
            {
                notificationsArr.length === 0 && !isError &&
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1rem' }}>
                    <Typography sx={{ color: '#555', fontSize: '1rem' }}>No Notifications</Typography>
                </Box>
            }
            {
                isError && <Typography sx={{ color: '#555', fontSize: '1rem' }}>Something went wrong!</Typography>
            }
        </Box >
    )
}


export default RenderNotifications;