import { Box } from '@mui/material'
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { LabelStylesGen, StyleInput } from '../../style/styles';
import TextField from '@mui/material/TextField';

const Textarea = (props) => {
    const { rows, label, labelVariant, textFieldProps } = props;

    const id = uuidv4();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <label
                style={LabelStylesGen(labelVariant)}
                htmlFor={id}
            >
                {label}
            </label>


            <textarea
                className='general-input'
                rows={rows}
                id={id}
                style={{ ...StyleInput, resize: 'none' }}
                {...textFieldProps}
            />

        </Box>
    )
}

Textarea.propTypes = {
    label: PropTypes.string.isRequired,
    labelVariant: PropTypes.oneOf(['large']),
    rows: PropTypes.number,
}

export default Textarea;
