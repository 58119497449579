const firebaseConfig = {
    apiKey: "AIzaSyD1vNDBULcu1O28qrtus2T5RAp3enFXjKI",
    authDomain: "tabrasa-4f5e3.firebaseapp.com",
    projectId: "tabrasa-4f5e3",
    storageBucket: "tabrasa-4f5e3.appspot.com",
    messagingSenderId: "824864892249",
    appId: "1:824864892249:web:ef4747aa6795e1f6441848"
};

const firebaseBucketUrl = "gs://tabrasa-4f5e3.appspot.com";

export { firebaseConfig, firebaseBucketUrl };
