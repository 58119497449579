import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const GoldenButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    padding: '6px 20px',
    border: 'none',
    fontWeight: '500',
    backgroundColor: '#CBA135',
    color: 'white',
    borderRadius: '0.375rem',
    '&.Mui-disabled': {
        color: 'white',
        backgroundColor: '#e0c786',
        opacity: '0.75',
    },
    '&:hover': {
        backgroundColor: '#a2812a',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#7a6120',
        transform: 'scale(0.95)'
    },
});

export default GoldenButton;
