import { Divider } from "@mui/material";
import PropTypes from 'prop-types';

const CustomDivider = ({ sx }) => {
    return (
        <Divider sx={{ my: 4, borderBottomWidth: ' 0.063rem', ...sx }} />
    );
}

export default CustomDivider;

CustomDivider.prototype = {
    sx: PropTypes.object
}
