import { lazy } from 'react';
import Loadable from './loadable';
import ProtectLoginPages from './protected/ProtectLoginPage';

const PageMessages = Loadable(lazy(() => import('../pages/Messages/PageMessages')));

const NoLayoutRoutes = {
    path: '/',
    children: [
        {
            path: '/messages',
            element: <ProtectLoginPages>
                <PageMessages />
            </ProtectLoginPages>
        }
    ]
};

export default NoLayoutRoutes;
