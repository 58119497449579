import customAxios from "../../util/axios";
import { PAGINATION_LIMIT } from "../../util/constants";
import { useQueryClient, useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query';



export function useGetNotifications() {

    const queryFn = async (pageParam) => {
        const response = await customAxios({
            method: 'get',
            url: `/api/notification?page=${pageParam}&limit=${PAGINATION_LIMIT}`
        })
        return response.data;
    }

    return useInfiniteQuery({
        queryKey: ['notification'],
        queryFn: ({ pageParam = 1 }) => queryFn(pageParam),
        getNextPageParam: (lastPage, allPages) => {
            const nextPage =
                lastPage?.data?.length === PAGINATION_LIMIT ? allPages?.length + 1 : undefined;
            return nextPage;
        },
        retryOnNetworkError: true,
        refetchOnWindowFocus: false,
    });
}

export function useGetCountNonReadNotifications() {

    const queryFn = async () => {

        const response = await customAxios({
            method: 'get',
            url: `/api/notification/countNotReadNotifications`
        })
        return response.data;
    }

    return useQuery({
        queryKey: ['countNotification'],
        queryFn,
        retry: 1,
        retryOnNetworkError: true,
        refetchOnWindowFocus: false,
    });
}

export function useMarkAllNotificationsAsRead() {
    const queryClient = useQueryClient();

    const mutationFn = async () => {

        const response = await customAxios({
            method: 'patch',
            url: `/api/notification/markAllNotificationsAsRead`
        })
        return response.data;
    }

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries('countNotification');
            queryClient.invalidateQueries('notification');
        }
    });
}

export function useDeleteNotificationById() {
    const queryClient = useQueryClient();

    const mutationFn = async (id) => {

        const response = await customAxios({
            method: 'delete',
            url: `/api/notification/${id}`
        })
        return response.data;
    }

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries('notification');
            queryClient.invalidateQueries('countNotification');
        }
    });
}

export function useDeleteAllNotifications() {
    const queryClient = useQueryClient();

    const mutationFn = async () => {

        const response = await customAxios({
            method: 'delete',
            url: `/api/notification`
        })
        return response.data;
    }

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries('notification');
            // queryClient.invalidateQueries('countNotification');
            queryClient.setQueryData(['countNotification'], {
                totalDocuments: 0
            });
        }
    });
};


export function useUpdateNotificationReadStatusById() {
    const queryClient = useQueryClient();

    const mutationFn = async (id) => {

        const response = await customAxios({
            method: 'patch',
            url: `/api/notification/updateReadStatus/${id}`
        })
        return response.data;
    }

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries('notification');
            queryClient.invalidateQueries('countNotification');
        }
    });
}