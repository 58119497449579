import { Suspense } from 'react';
import PageLoadingSpinner from '../components/PageLoadingSpinner';

const Loadable = (Component) => (props) =>
(
    <Suspense fallback={<PageLoadingSpinner />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;
