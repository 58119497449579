import { combineReducers } from "redux";
import userReducer from "./slices/userSlice";
import immigrationProfileReducer from "./slices/immigrationProfile.slice";
import appReducer from "./slices/appSlice";
import cartReducer from "./slices/cartSlice";

const combinedReducers = combineReducers({
    user: userReducer,
    immigrationProfile: immigrationProfileReducer,
    app: appReducer,
    cart: cartReducer
})

export const rootReducer = (state, action) => {
    if (action.type === 'RESET') {
        return combinedReducers(undefined, action)
    }

    return combinedReducers(state, action)
}
