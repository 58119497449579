import ErrorPage from "../../components/ErrorPage";
import { useSelector } from "react-redux";
import { dispatch } from "../../store/store";
import { getApplicantProfile } from "../../store/slices/userSlice";
import Loadable from "../loadable";
import { lazy } from "react";
import { MainLayoutNonReactRouterDOM } from "../../layouts/MainLayout";


const AccessDenied = () => (
    <ErrorPage
        showHomeButton={true}
        showBackButton={false}
        primaryText="Access Denied"
        secondaryText="You are either not logged in as an Applicant, or you are not authorized to access this page."
    />
);

const PageVerifyAccount = Loadable(lazy(() => import('../../pages/Auth/PageVerifyAccount')));

const ProtectApplicantRoutes = ({ children }) => {
    const { loading, data } = useSelector(state => state?.user);

    if (loading) { dispatch(getApplicantProfile({ type: 'applicant' })); }


    if (!loading && data && !(data?.isAccountVerified)) {
        return <MainLayoutNonReactRouterDOM>
            <PageVerifyAccount />
        </MainLayoutNonReactRouterDOM>
    }



    const storedData = JSON.parse(localStorage.getItem('immigrationJobUserData'));

    return (storedData && storedData?.userType === 'applicant') ? children : <AccessDenied />;
}

export default ProtectApplicantRoutes;
