import { FormHelperText } from "@mui/material";

const CustomHelperText = ({ children }) => {
    return (
        <FormHelperText
            sx={{
                color: '#D80621',
                fontSize: '0.725rem',
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '1.25rem',
                '&:first-letter': {
                    textTransform: 'capitalize',
                }
            }}
        >
            {children}
        </FormHelperText>
    );
}

export default CustomHelperText;