import ErrorPage from "../../components/ErrorPage";


const AccessDenied = () => (
    <ErrorPage
        primaryText="Access Denied"
        secondaryText="This page is not available right now. Please contact support if problem persists."
    />
);


const ProtectNonLoginRoutes = ({ children }) => {
    const storedData = JSON.parse(localStorage.getItem('immigrationJobUserData'));

    return !(storedData && storedData?.userType) ? children : <AccessDenied />;
}

export default ProtectNonLoginRoutes;