import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { dispatch } from "../../store/store";
import { setMenuModalAction } from "../../store/slices/appSlice";
import { useSelector } from "react-redux";
import { AuthContext } from '../../context/AuthContext';
import { Box, IconButton, Typography } from '@mui/material';
import { StylesMenuDialogPaperProps } from '../../style/styles';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import EditPhotoUrl from '../EditPhotoUrl';
import { useGetQuotaUsage } from '../../store/api/stripeApi';
import StyledBadge from '../ui/StyledBadge';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const navItemsForApplicant = [
    {
        id: 1,
        name: 'My Profile',
        path: '/applicant/profile'
    },
    {
        id: 5,
        name: 'Immigration Profile',
        path: '/applicant/immigration-profile',
    },
    {
        id: 2,
        name: 'Manage Account',
        path: '/applicant/manage-account'
    },
    {
        id: 'my-subscription',
        name: 'My subscription',
        path: '/applicant/subscription'
    },
    {
        id: 'cart',
        name: 'Cart',
        path: '/applicant/cart'
    }
]

const navItemsForImmigrationFirm = [
    {
        id: 1,
        name: 'My Profile',
        path: '/immigration-firm/profile',
    },
    {
        id: 2,
        name: 'Manage Account',
        path: '/immigration-firm/manage-account'
    },
    {
        id: 'cart',
        name: 'Cart',
        path: '/immigration-firm/cart'
    },
    {
        id: 'my-subscription',
        name: 'My subscription',
        path: '/immigration-firm/subscription'
    },
]

export default function MenuDialog() {
    const auth = React.useContext(AuthContext);
    const open = useSelector(state => state?.app?.menuModal?.open);

    const setOpen = (value) => { dispatch(setMenuModalAction({ open: value })); }

    const user = useSelector(state => state?.user);

    const navItems = auth?.userType === 'applicant' ? navItemsForApplicant : navItemsForImmigrationFirm;




    return (
        <div>
            <Dialog
                PaperProps={{
                    sx: StylesMenuDialogPaperProps,
                }}
                open={open}
                fullScreen
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby="User Account Menu"
                disableScrollLock
            >
                <Box>
                    <IconButton
                        disableRipple
                        size='small'
                        onClick={() => setOpen(false)}
                        sx={{ '&:hover': { backgroundColor: '#F6F6F6' }, position: 'absolute', top: 10, right: 10 }} aria-label="delete"
                    >
                        <CloseIcon sx={{ fontSize: { xs: '2rem', sm: '1.5rem' }, color: 'black' }} />
                    </IconButton>


                    <Box sx={{ display: 'flex' }}>
                        <EditPhotoUrl />
                    </Box>

                    <Typography noWrap sx={{ textTransform: 'capitalize', mt: 3, fontWeight: 600, fontSize: '1.5rem', color: '#1d1d1d' }}>
                        {user?.data?.name}
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3, gap: 1 }}>
                        {
                            navItems.map((item, index) => (
                                <Link onClick={() => { setOpen(false); }} className='reset-anchor-styling ' key={index} to={item.path}>
                                    <Typography component={"span"} sx={{ mr: 2, '&:hover': { opacity: 0.75 }, p: 0, cursor: 'pointer', fontWeight: 500, fontSize: '0.85rem', color: '#555' }}>
                                        {item.name}
                                    </Typography>
                                    {
                                        item.id === 'my-subscription' && (
                                            <RenderIsActive />
                                        )
                                    }
                                    {
                                        item.id === 'cart' && (
                                            <RenderCountCartItems />
                                        )
                                    }
                                </Link>
                            ))
                        }
                    </Box>

                    <Button sx={{ "&:hover": { opacity: 0.8 }, mt: 3 }} size="small" onClick={auth.logout}>
                        <Typography component={"span"} sx={{ p: 0, cursor: 'pointer', fontWeight: 600, fontSize: '1rem', color: '#1d1d1d' }} >Logout</Typography>
                    </Button>
                </Box>
            </Dialog>
        </div >
    );
}

const RenderIsActive = () => {
    const { isLoading, data } = useGetQuotaUsage();

    const isActive = !(data?.planType === 'undefined' || data?.planType === 'free' || data?.planType === null);


    if (isLoading) {
        return null;
    }

    return (
        <Typography component={"span"} sx={{
            border: '0.75px solid #47C799', borderRadius: '0.375rem',
            p: '0.28125rem 0.5625rem',
            cursor: 'pointer', fontWeight: 500, fontSize: '0.65rem',
            color: '#49454F',
            '&:hover': {
                backgroundColor: '#47C799',
                color: '#fff'
            }
        }}>
            {
                isActive ? 'Active' : 'Inactive'
            }
        </Typography>

    );
}


const RenderCountCartItems = () => {
    const countItems = useSelector(state => state?.cart?.items?.length) || 0;

    return <StyledBadge badgeContent={countItems} color="error" />
}