import { useRoutes } from 'react-router-dom';
import ApplicantRoutes from './ApplicantRoutes';
import GeneralRoutes from './GeneralRoutes';
import ImmigrationFirmRoutes from './ImmigrationFirmRoutes';
import AuthRoutes from './AuthRoutes';
import NoLayoutRoutes from './NoLayoutRoutes';

export default function RootRouter() {
    return useRoutes([
        GeneralRoutes,
        AuthRoutes,
        NoLayoutRoutes,
        ApplicantRoutes,
        ImmigrationFirmRoutes
    ]);
}
