

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar({ fontSize, str, width, height, sx }) {
    let children;
    let inputLen = str?.split(' ')?.length;
    if (inputLen === 0) {
        children = 'N A';
    } else if (inputLen === 1) {
        children = str?.split(' ')[0][0];
    } else {
        children = `${str?.split(' ')[0][0]}${str?.split(' ')[1][0]}`;
    }

    children = children?.toUpperCase();

    return {
        sx: {
            width,
            height,
            fontSize: fontSize || '2.5rem',
            bgcolor: stringToColor(str),
            ...sx
        },
        children: children
    };
}

export const calcProfileCompletion = (user) => {

    let profileCompletion = 0;
    let params = [
        {
            param: 'name',
            type: 'string'
        },
        {
            param: 'resume',
            type: 'array'
        },
        {
            param: 'phoneNumber',
            type: 'string'
        },
        {
            param: 'maritalStatus',
            type: 'string'
        },
        {
            param: 'noOfChildren',
            type: 'number'
        },
        {
            param: 'provincesOfCanadaWhereInterestedToWork',
            type: 'array'
        },
        {
            param: 'statusInCountryOfResidence',
            type: 'string'
        },
        {
            param: 'areaOfInterest',
            type: 'string'
        },
        {
            param: 'workExperience',
            type: 'string'
        },
        {
            param: 'salaryExpectation',
            type: 'number'
        },
        {
            param: 'highestLevelOfEducation',
            type: 'string'
        }

    ];

    // calculate profile completion in percentage
    params.forEach((param) => {
        if (param?.type === 'string') {
            if (user[param?.param] && user[param?.param]?.length > 0) {
                profileCompletion += 1;
            }
        } else if (param?.type === 'number') {
            if (user[param?.param] && user[param?.param] > 0) {
                profileCompletion += 1;
            }
        } else if (param?.type === 'array') {
            if (user[param?.param] && user[param?.param]?.length > 0) {
                profileCompletion += 1;
            }
        }
    });


    // return (profileCompletion / params.length) * 100;
    // round to nearest integer
    return Math.round((profileCompletion / params.length) * 100);

};
