import { CircularProgress } from "@mui/material";
import PropTypes from 'prop-types';

const CustomCircularProgress = ({ sx, ...props }) => {
    return (
        <CircularProgress
            {...props}
            sx={{
                color: (theme) =>
                    theme.palette.grey[theme.palette.mode === 'light' ? 800 : 200],
                ...sx,
            }}
        />
    );
}

export default CustomCircularProgress;

CustomCircularProgress.propTypes = {
    sx: PropTypes.object,
};
