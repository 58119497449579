import { Avatar, Box, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { dispatch } from "../../store/store";
import { setMenuModalAction } from "../../store/slices/appSlice";
import MenuDialog from "./MenuDialog";
import { stringAvatar } from "../../pages/Applicant/ApplicantProfile/ProfileBar/profileBarHelperFuncs";
import { useSelector } from "react-redux";
import { getApplicantProfile } from "../../store/slices/userSlice";
import { getUserTypeSync } from "../../util/getUserToken";
import Notification from "../Notification/Notification";

const RightMenuTopNavbar = () => {
    const userTypeSync = getUserTypeSync();

    const user = useSelector(state => state?.user);

    if (user?.loading) {
        dispatch(getApplicantProfile());
        return <Skeleton animation={false} sx={{ backgroundColor: '#EFEFEF' }} variant="circular" width={32} height={32} />
    }


    const userType = userTypeSync === 'applicant' ? 'Applicant' : (userTypeSync === 'immigration-firm') ? 'Employer' : 'Loading...';



    const handleOpenMenu = () => {
        dispatch(setMenuModalAction({ open: true }));
    }

    return (
        <>
            <Notification />
            <MenuDialog />
            <Tooltip
                // title="Settings"
                title={
                    <Box>
                        <Typography
                            sx={{ fontSize: '0.8rem' }}
                            variant={"body2"}
                        >Account: {userType}</Typography>
                        <Typography
                            sx={{ fontSize: '0.7rem', textTransform: "capitalize", color: "lightgray" }}
                            variant={"body2"}
                        >
                            {user?.data?.name}
                        </Typography>
                        <Typography variant={"body2"} sx={{ fontSize: '0.7rem', color: "lightgray" }}>
                            {user?.data?.email}
                        </Typography>
                    </Box>

                }
            >
                <IconButton
                    aria-label="menu"
                    onClick={handleOpenMenu}
                    size="small"
                // sx={{ '&:hover': { filter: 'brightness(1.2)',} }}
                >
                    <Avatar
                        src={user?.data?.photo?.url}

                        {...stringAvatar({
                            str: user?.data?.name || 'Un Known',
                            width: '32px',
                            height: '32px',
                            fontSize: '1rem',
                        })}
                    />
                </IconButton>
            </Tooltip>
        </>
    );
}

export default RightMenuTopNavbar;
