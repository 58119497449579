import ErrorPage from "../../components/ErrorPage";
import { useSelector } from "react-redux";
import { dispatch } from "../../store/store";
import { getApplicantProfile } from "../../store/slices/userSlice";
import { MainLayoutNonReactRouterDOM } from "../../layouts/MainLayout";
import Loadable from "../loadable";
import { lazy } from "react";

const PageAddCompanyDetails = Loadable(
    lazy(() =>
        import(
            "../../pages/ImmigrationFirm/PageAddCompanyDetails/PageAddCompanyDetails"
        )
    )
);
const PageVerifyAccount = Loadable(
    lazy(() => import("../../pages/Auth/PageVerifyAccount"))
);

const AccessDenied = () => (
    <ErrorPage
        showHomeButton={true}
        showBackButton={false}
        primaryText="Access Denied"
        secondaryText="You are either not logged in as an Employer, or you are not authorized to access this page."
    />
);

const ProtectImmigrationFirmRoutes = ({ children }) => {
    const { loading, data } = useSelector((state) => state?.user);

    if (loading) {
        dispatch(getApplicantProfile());
    }




    // ! FLOW
    // 1. IF ACCOUNT IS NOT VERIFEID, SHOW VERIFY ACCOUNT PAGE
    // 2. IF ACCOUNT IS VERIFIED BUT COMPANY DETAILS ARE NOT ADDED, SHOW ADD COMPANY DETAILS PAGE
    if (!loading && data && !data?.isAccountVerified) {
        return (
            <MainLayoutNonReactRouterDOM>
                <PageVerifyAccount />
            </MainLayoutNonReactRouterDOM>
        );
    } else if (!loading && data && !data?.isCompanyDetailsAdded) {
        return (
            <MainLayoutNonReactRouterDOM>
                <PageAddCompanyDetails />
            </MainLayoutNonReactRouterDOM>
        );
    }

    const storedData = JSON.parse(
        localStorage.getItem("immigrationJobUserData")
    );

    return storedData && storedData?.userType === "immigration-firm" ? (
        children
    ) : (
        <AccessDenied />
    );
};

export default ProtectImmigrationFirmRoutes;
