import { Box, Container } from "@mui/material";
import Error from "./Error";
import PropTypes from 'prop-types';
import CustomHelmet from "./CustomHelmet";

const ErrorPage = ({ icon, showBackButton, showHomeButton, primaryText, secondaryText,actionButton }) => {
    return (
        <>
            <CustomHelmet title={primaryText || 'Something wrong!'} />
            <Container>
                <Box sx={{ minHeight: '85vh' }} className="centered">
                    <Error
                        icon={icon}
                        showBackButton={showBackButton}
                        showHomeButton={showHomeButton}
                        primaryText={primaryText}
                        secondaryText={secondaryText}
                        actionButton={actionButton}
                    />
                </Box>
            </Container>
        </>
    );
}

export default ErrorPage;

ErrorPage.prototype = {
    icon: PropTypes.element,
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    showBackButton: PropTypes.bool,
    showHomeButton: PropTypes.bool,
    actionButton: PropTypes.object,
}

// default props
ErrorPage.defaultProps = {
    primaryText: "Error: Unknown",
    secondaryText: "Something went wrong. Please contact support for help, if the problem persists.",
    showBackButton: true,
    showHomeButton: false,
    actionButton: null,
}