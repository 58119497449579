import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { ReactComponent as FacebookIcon } from "../assets/icons/facebook.svg";
import { ReactComponent as LinkedInIcon } from "../assets/icons/LinkedIn.svg";
import { ReactComponent as YouTubeIcon } from "../assets/icons/Youtube.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/Instagram.svg";
import AppLogo from "../assets/white-on-black-text-only.png";
import { getUserTypeSync, isLoggedInSync } from "../util/getUserToken";
import { Link } from "react-router-dom";
import { StylesFooterSlogan } from "../style/styles";
import ContactFormModal from "../components/ContactForm/ContactFormModal";
const StylesHeading = {
  color: "#FFF",
  fontSize: "1.25rem",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
};

const ForJobSeekersLinks = [
  {
    id: 1,
    title: "Job Search",
    link: "/auth/register",
  },
  {
    id: 2,
    title: "Resume",
    link: "/resources/blogs",
  },
  {
    id: 3,
    title: "Career Advice",
    link: "/resources/blogs",
  },
  {
    id: 4,
    title: "Salary",
    link: "/resources/blogs",
  },
  {
    id: 5,
    title: "Interview",
    link: "/resources/blogs",
  },
];

const ForEmployersLinks = [
  {
    id: 1,
    title: "Post a Job",
    link: "/auth/register",
  },
  {
    id: 2,
    title: "Resume Search",
    link: "/auth/register",
  },
  {
    id: 3,
    title: "Career Page",
    link: "/auth/register",
  },
  {
    id: 4,
    title: "Talent Acquisition",
    link: "/auth/register",
  },
];

const AboutLinks = [
  {
    id: 1,
    title: "About Us",
    link: "/about-us",
  },
  {
    id: 2,
    title: "Contact Us",
    link: "?contact=open",
  },
  {
    id: 3,
    title: "Terms of Service",
    link: "/terms-of-service",
  },
];

export const SocialMedialLinks = [
  {
    id: 1,
    title: "Facebook",
    icon: FacebookIcon,
    link: "https://www.facebook.com/",
  },
  {
    id: 2,
    title: "LinkedIn",
    icon: LinkedInIcon,
    link: "https://www.linkedin.com/",
  },
  {
    id: 3,
    title: "Youtube",
    icon: YouTubeIcon,
    link: "https://www.youtube.com/",
  },
  {
    id: 4,
    title: "Instagram",
    icon: InstagramIcon,
    link: "https://www.instagram.com/",
  },
];

const footerLinksImmigrationFirm = [
  {
    id: 1,
    title: "Post a job",
    link: "/immigration-firm/jobs/new",
  },
  {
    id: 2,
    title: "Resume Search",
    link: "#",
  },
  {
    id: 3,
    title: "Update Profile",
    link: "/immigration-firm/profile",
  },
  {
    id: 4,
    title: "My Subscription",
    link: "/immigration-firm/subscription",
  },
  {
    id: 5,
    title: "Talent Acquisition",
    link: "/immigration-firm/search-job-seekers",
  },
  {
    id: 6,
    title: "About Us",
    link: "/about-us",
  },
  {
    id: 7,
    title: "Terms of Service",
    link: "/terms-of-service",
  },
  {
    id: 8,
    title: "Contact Us",
    link: "?contact=open",
  },
];
const footerLinksApplicant = [
  {
    id: 1,
    title: "Job Search",
    link: "/applicant/search-jobs?category=newListings",
  },
  {
    id: 2,
    title: "Resume",
    link: "/applicant/profile",
  },
  {
    id: 3,
    title: "Career Advice",
    link: "/applicant/resources",
  },
  {
    id: 4,
    title: "My Profile",
    link: "/applicant/profile",
  },
  {
    id: 5,
    title: "Interview Support",
    link: "/applicant/resources",
  },
  {
    id: 6,
    title: "My Subscription",
    link: "/applicant/subscription",
  },
  {
    id: 7,
    title: "Terms of Service",
    link: "/terms-of-service",
  },
  {
    id: 8,
    title: "Contact Us",
    link: "?contact=open",
  },
];

export function RenderSocialMediaLinks({ links }) {
  return (
    <Box sx={{ display: "flex", gap: 3 }}>
      {links.map((link) => {
        const Icon = link.icon;
        return (
          <a
            href={link.link}
            target="__blank"
            key={link.id}
            className="footer-social-media-link"
          >
            <Icon />
          </a>
        );
      })}
    </Box>
  );
}

function RenderLinks({ links }) {
  return links.map((link) => (
    <Typography
      key={link.id}
      sx={{
        mt: { xs: 1, md: 1.5 },
        color: "#FFF",
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      }}
    >
      <Link to={link?.link} className="footer-link">
        {link.title}
      </Link>
    </Typography>
  ));
}

const Footer = () => {
  const userType = getUserTypeSync();
  const isLoggedIn = isLoggedInSync();

  return (
    <Box
      component="footer"
      sx={{ color: "white", backgroundColor: "#1D1D1D", p: "5rem 0" }}
    >
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} lg={4}>
            <img style={{ maxWidth: "200px" }} src={AppLogo} alt="logo" />
            <Typography sx={StylesFooterSlogan}>
              Your Success, Our Focus
            </Typography>
            <RenderSocialMediaLinks links={SocialMedialLinks} />
          </Grid>

          {!isLoggedIn && <FooterNotLoggedIn />}

          {isLoggedIn && (
            <RenderLinksForLoggedin
              links={
                userType === "immigration-firm"
                  ? footerLinksImmigrationFirm
                  : footerLinksApplicant
              }
            />
          )}
        </Grid>
        <Box
          sx={{
            mt: 5,
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "0.875rem",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            © 2023-{new Date().getFullYear()}, Tikai ® Inc.
          </Typography>
        </Box>
      </Container>
      <ContactFormModal />
    </Box>
  );
};

const RenderLinksForLoggedin = ({ links }) => (
  <Grid item xs={12} sm={6} lg={8}>
    <Stack
      flexWrap={"wrap"}
      direction={"row"}
      alignItems={"center"}
      justifyContent={{
        // xs: "center",
        xs: "space-between",
        md: "flex-start",
      }}
      //   spacing={5}
      columnGap={5}
      rowGap={{
        xs: 1,
        sm: 4,
        // md: 4,
        lg: 10,
      }}
    >
      {links.map((link) => (
        <Typography
          key={link.id}
          sx={{
            textAlign: "left",
            color: "#FFF",
            fontSize: "0.875rem",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          <Link to={link.link} className="footer-link">
            {link.title}
          </Link>
        </Typography>
      ))}
    </Stack>
  </Grid>
);

const FooterNotLoggedIn = () => (
  <>
    <Grid item xs={12} sm={6} lg={2.66}>
      <Typography sx={StylesHeading}>For Job Seekers </Typography>
      <RenderLinks links={ForJobSeekersLinks} />
    </Grid>
    <Grid item xs={12} sm={6} lg={2.66}>
      <Typography sx={StylesHeading}>For Employers </Typography>
      <RenderLinks links={ForEmployersLinks} />
    </Grid>
    <Grid item xs={12} sm={6} lg={2.66}>
      <Typography sx={StylesHeading}>About </Typography>
      <RenderLinks links={AboutLinks} />
    </Grid>
  </>
);

export default Footer;
