import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { dispatch } from '../store/store';
import { useQueryClient } from '@tanstack/react-query';

export const useAuth = () => {
    const queryClient = useQueryClient();

    const [token, setToken] = useState();
    const [userType, setUserType] = useState();
    const [userId, setUserId] = useState();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const login = useCallback((token, userType, userId) => {
        setLoading(true);
        setToken(token);
        setUserType(userType);
        setUserId(userId);

        localStorage.setItem(
            'immigrationJobUserData',
            JSON.stringify({
                token: token,
                userType: userType,
                userId: userId
            })
        );
        setLoading(false);
    }, []);

    const logout = useCallback(() => {
        setLoading(true);
        setToken(null);
        setUserType(null);
        setUserId(null);
        localStorage.removeItem('immigrationJobUserData');
        setLoading(false);
        dispatch({ type: "RESET" });
        navigate('/');
        // reset the react-query cache
        queryClient.clear();
    }, []);

    useEffect(() => {
        setLoading(true);
        const storedData = JSON.parse(localStorage.getItem('immigrationJobUserData'));
        if (storedData && storedData.token) {
            login(storedData.token, storedData.userType, storedData.userId);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [login]);

    return { loading, token, userType, userId, login, logout };
};