import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { getUserToken, getUserType, getUserTypeSync } from '../../util/getUserToken';
import axios from 'axios';

const initialState = {
    loading: true,
    updating: false,
    error: null,
    data: {}
};

const slice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setData(state, action) {
            state.data = action.payload;
        },

        setLoading(state, action) {
            state.loading = action.payload;
        },

        setUpdating(state, action) {
            state.updating = action.payload;
        },

        deleteResume(state, action) {

            // resume is an array of resume objects, where each object has a resumeURL and fileName
            let resume = state.data.resume;
            let index = resume.findIndex((resume) => resume.resumeURL === action.payload);
            if (index > -1) {
                resume.splice(index, 1);
            }
            state.data.resume = resume;

        },

        hasError(state, action) {
            state.error = action.payload;
        }
    },
})


// type :'immigration-firm' || 'applicant'
export function getApplicantProfile() {
    return async () => {
        try {
            let token = await getUserToken();
            let config = {
                method: 'get',
                url: getUserTypeSync() === 'immigration-firm' ? '/api/immigration-firm/profile' : '/api/applicant/profile',
                headers: {
                    'Authorization': `bearer ${token}`
                }
            };
            dispatch(slice.actions.setLoading(true));
            let response = await axios(config);
            dispatch(slice.actions.setLoading(false));

            if (response.status === 200) {
                dispatch(slice.actions.setData(response?.data));
            } else {
                dispatch(slice.actions.hasError(response?.data?.message));
            }


        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error?.response?.data?.message));
        }
    };
}



// this function is used to update profile of both applicant and immmigration-firm
export function updateUserProfileAction(data) {


    return async () => {
        try {
            let token = await getUserToken();
            let userType = await getUserType();
            const url = userType === 'immigration-firm' ? '/api/immigration-firm/profile' : '/api/applicant/profile';


            let config = {
                method: 'patch',
                url,
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            dispatch(slice.actions.setUpdating(true));
            let response = await axios(config);
            dispatch(slice.actions.setUpdating(false));

            if (response.status === 200) {
                dispatch(slice.actions.setData(response?.data));
            }
            else {
                dispatch(slice.actions.hasError(response?.data?.message));
                return { success: false, error: response?.data?.message }
            }

            return { success: true }

        } catch (error) {
            dispatch(slice.actions.hasError(error?.response?.data?.message));
            return { success: false, error: error?.response?.data?.message }
        }
    }
}

export function addResume(data) {
    return async () => {
        try {
            let token = await getUserToken();
            let config = {
                method: 'patch',
                url: '/api/applicant/update-resume-url',
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            dispatch(slice.actions.setUpdating(true));
            let response = await axios(config);
            dispatch(slice.actions.setUpdating(false));

            if (response.status === 200) {
                dispatch(slice.actions.setData(response?.data));
            }
            else {
                dispatch(slice.actions.hasError(response?.data?.message));
                return { success: false, error: response?.data?.message }
            }

            return { success: true }

        } catch (error) {
            dispatch(slice.actions.hasError(error?.response?.data?.message));
            return { success: false, error: error?.response?.data?.message }
        }
    }
}

export function deleteResume(url) {
    return async () => {
        try {
            let token = await getUserToken();
            let config = {
                method: 'delete',
                url: '/api/applicant/delete-resume',
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({ urlToBeDeleted: url })
            };

            dispatch(slice.actions.setUpdating(true));
            let response = await axios(config);
            dispatch(slice.actions.setUpdating(false));

            if (response.status === 200) {
                dispatch(slice.actions.deleteResume(url));
            }
            else {
                dispatch(slice.actions.hasError(response?.data?.message));
                return { success: false, error: response?.data?.message }
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error?.response?.data?.message));
            return { success: false, error: error?.response?.data?.message }
        }
    }
}


const userReducer = slice.reducer;
export default userReducer;
