import { List, ListItem, ListItemButton, ListItemText, Paper, Typography } from "@mui/material";
import CustomDivider from "../../ui/CustomDivider";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { getUserTypeSync } from "../../../util/getUserToken";
import { useEffect, useMemo, useState } from "react";
import { useGetSearchResults } from "../../../store/api/searchApi";
import { StylesPaperSearchTopNavBar } from "../../../style/styles";
const initialState = { main: [], blogs: [], resources: [] };

// ? Shape of data is available at the bottom of this file

const PaperSearchTopNavbar = ({ searchValue, closeSearchPopper }) => {
    const { refetch, data: dbData } = useGetSearchResults({ q: searchValue });


    const userType = getUserTypeSync();

    //? START - DEBOUNCING MECHANISIM
    const [filterTimeout, setFilterTimeout] = useState(null);
    const [data, setData] = useState(initialState);
    const handlerSearchDebounce = () => {
        clearTimeout(filterTimeout)
        if (!searchValue || searchValue?.trim() === "") {
            setData(initialState);
            return;
        }

        setFilterTimeout(setTimeout(() => { refetch(); }, 500))
    }

    // when search value changes, we need to refetch the data through debouncing
    useEffect(() => { handlerSearchDebounce(searchValue); }, [searchValue]);

    // when data is fetched, we need to update the state
    useEffect(() => { if (dbData) { setData(dbData); } }, [dbData]);

    //? END- DEBOUNCING MECHANISIM





    // TODO: change this url
    const viewAllMainSearch = useMemo(() => { return userType === 'applicant' ? `/applicant/search-jobs?search=${searchValue}` : `/immigration-firm/search-job-seekers?search=${searchValue}` }, [userType, searchValue]);


    // if (isFetching) return (<SekeletonSearchTopNavbar />)

    if (!searchValue || searchValue?.trim() === "") return (
        <Paper sx={StylesPaperSearchTopNavBar}>
            <Typography sx={{ mt: 2, fontSize: '0.875rem', color: '#323C3E', fontWeight: 400, mx: 2 }}>
                Search for
                {userType === 'applicant' ? ' jobs, ' : ' job seekers, '}
                blogs, resources and more
            </Typography>
        </Paper>
    );


    return (
        <Paper sx={StylesPaperSearchTopNavBar}>

            <RenderList
                data={data?.main}
                baseUrl={userType === 'applicant' ? "/applicant/view-job" : "/immigration-firm/view-job-seeker"}
                onLinkClick={closeSearchPopper}
                viewAllUrl={viewAllMainSearch}
            />


            <CustomDivider sx={{ mx: 2, my: 1 }} />
            <Typography sx={{ mt: 2, fontSize: '0.875rem', color: '#323C3E', fontWeight: 600, mx: 2 }}>
                Blogs
            </Typography>

            <RenderList
                data={data?.blogs}
                baseUrl={userType === 'applicant' ? "/applicant/blogs" : "/immigration-firm/blogs"}
                onLinkClick={closeSearchPopper}
                maxResults={2}
                hideViewAll
            />

            <CustomDivider sx={{ mx: 2, my: 1 }} />
            <Typography sx={{ mt: 2, fontSize: '0.875rem', color: '#323C3E', fontWeight: 600, mx: 2 }}>
                Resources
            </Typography>

            <RenderList
                data={data?.resources}
                fullUrl={userType === 'applicant' ? "/applicant/resources" : "/immigration-firm/resources"}
                onLinkClick={closeSearchPopper}
                maxResults={2}
                hideViewAll
            />

        </Paper>
    );
}

export default PaperSearchTopNavbar;

PaperSearchTopNavbar.prototype = {
    closeSearchPopper: PropTypes.func.isRequired
};


const RenderList = ({ hideViewAll, viewAllUrl, onLinkClick, data, baseUrl, fullUrl, maxResults }) => {
    return <List dense>
        {
            data
                ?.slice(0, maxResults)
                ?.map((item) => {
                    return (
                        <ListItem key={item?._id} disablePadding>
                            <Link className="reset-anchor-styling" style={{ width: '100%' }} to={
                                fullUrl ? fullUrl : `${baseUrl}/${item?._id}`
                            }>
                                <ListItemButton disableRipple onClick={onLinkClick}>
                                    <ListItemText disableTypography primary={
                                        <Typography noWrap sx={{ fontSize: '0.875rem', color: '#323C3E', fontWeight: 400 }}>
                                            {item.title}
                                        </Typography>
                                    } />
                                </ListItemButton>
                            </Link>
                        </ListItem>

                    )
                })
        }
        {
            (!hideViewAll && data?.length > maxResults) && <ListItem disablePadding>
                <Link className="reset-anchor-styling" style={{ width: '100%' }} to={`${viewAllUrl || ''}`}>
                    <ListItemButton disableRipple onClick={onLinkClick}>
                        <ListItemText disableTypography primary={
                            <Typography sx={{ fontSize: '0.875rem', color: '#323C3E', fontWeight: 600 }}>
                                View all
                            </Typography>
                        } />
                    </ListItemButton>
                </Link>
            </ListItem>
        }
        {
            data?.length === 0 && <ListItem disablePadding>
                <ListItemButton disabled disableRipple onClick={onLinkClick}>
                    <ListItemText disableTypography primary={
                        <Typography sx={{ fontSize: '0.875rem', color: '#323C3E', fontWeight: 400 }}>
                            No results
                        </Typography>
                    } />
                </ListItemButton>
            </ListItem>
        }
    </List>
};


RenderList.prototype = {
    viewAllUrl: PropTypes.string,
    onLinkClick: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    baseUrl: PropTypes.string.isRequired,
    maxResults: PropTypes.number,
    hideViewAll: PropTypes.bool
};

RenderList.defaultProps = {
    maxResults: 3,
    hideViewAll: false
};



// data = {
//     main: [
//         {
//             _id: '64f2287e51ebf83560059ce9',
//             title: 'Marketing manager'
//         }
//     ],
//     blogs: [
//         {
//             _id: '64e1d01197d3890351f7ccc0',
//             title: 'We empower your marketing goals'
//         }
//     ],
//     resources: [
//         {
//             _id: '64e1d01197d3890351f7ccc0',
//             title: 'Product Management Basic - Course'
//         },
//     ]
// }