import axios from 'axios';
import { getUserToken } from './getUserToken';

const customAxios = axios.create({
    baseURL: '',
});

customAxios.interceptors.request.use(
    async (config) => {
        let token = await getUserToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);


export default customAxios;
