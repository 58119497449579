import React from 'react';
import { ReactComponent as PageNotFoundSvg } from '../assets/images/pageNotFound.svg';
import { Box, Container } from '@mui/material';
import DarkButton from '../components/DarkButton';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import BottomPadder from '../components/ui/BottomPadder';
import CustomHelmet from '../components/CustomHelmet';

const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <>
            <CustomHelmet title='Page Not Found' />
            <Container>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 5 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <PageNotFoundSvg style={{ maxWidth: '100%' }} />
                        <Box>
                            <DarkButton
                                startIcon={<HomeIcon />}
                                sx={{ my: 4 }}
                                onClick={() => navigate('/')}
                            >
                                Go Home
                            </DarkButton>
                        </Box>
                    </Box>
                </Box>
                <BottomPadder />
            </Container>
        </>
    );
};

export default PageNotFound;
