export const PAGINATION_LIMIT = 10;

export const availableExperienceOptions = [
    {
        label: "Less than a year",
        value: "lessThanYear",
    },
    {
        label: "1-3 years",
        value: "1-3years",
    },
    {
        label: "3-5 years",
        value: "3-5years",
    },
    {
        label: "5-8 years",
        value: "5-8years",
    },
    {
        label: "8-10 years",
        value: "8-10years",
    },
    {
        label: "More than 10 years",
        value: "moreThan10years",
    },
];

export const getExperienceLabel = (value) =>
    availableExperienceOptions?.find((option) => option?.value === value)
        ?.label;

export const availableEducationLevels = [
  { value: "No formal education", label: "No formal education" },
  { value: "Primary education", label: "Primary education" },
  {
    value: "Secondary education",
    label: "Secondary education or high school",
  },
  { value: "Certificate/Diploma", label: "Certificate/Diploma" },
  { value: "GED", label: "GED" },
  { value: "Vocational qualification", label: "Vocational qualification" },
  { value: "Bachelor's degree", label: "Bachelor's degree" },
  { value: "Master's degree", label: "Master's degree" },
  { value: "Doctorate or higher", label: "Doctorate or higher" },
];

export const availableSalaryOptions = [
    { value: "0-9999999999", label: "Any" },
    { value: "30000-9999999999", label: "> 30,000k" },
    { value: "50000-9999999999", label: "> 50,000k" },
    { value: "80000-9999999999", label: "> 80,000k" },
    { value: "100000-9999999999", label: "> 100,000k" },
];

export const availableWorkModes = [
    { label: "Remote", value: "remote" },
    { label: "Work from office", value: "office" },
    { label: "Hybrid", value: "hybrid" },
    { label: "Contract", value: "contract" },
    { label: "Part Time", value: "part-time" },

];

export const availableCompanyTypeOptions = [
    {
        label: "Family-Owned Business",
        value: "Family-Owned-Business",
    },
    {
        label: "Government",
        value: "Government",
    },
    {
        label: "Multi-National Corporation",
        value: "Multi-National-Corporation",
    },
    {
        label: "Non-Profit",
        value: "Non-Profit",
    },
    {
        label: "Startup",
        value: "Startup",
    },
    {
        label: "Other",
        value: "Other",
    },
];

export const availableOrganizationOptions = [
    {
        value: "companyHiringDirectly",
        label: "Company Hiring Directly",
    },
    {
        value: "recruitementFirm",
        label: "Recruitement Firm",
    },
    {
        value: "immigrationConsultant",
        label: "Immigration Consultant",
    },
];
