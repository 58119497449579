

import InputBase from '@mui/material/InputBase';
import { ReactComponent as SearchIcon } from '../../../assets/icons/searchTopNavBar.svg';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import { Popper } from '@mui/base';
import PaperSearchTopNavbar from './PaperSearchTopNavbar';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '0.375rem',
    backgroundColor: '#fff',
    '&:hover': {
        backgroundColor: '#fff',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(1),
        marginLeft: 3.5,
        width: 'auto',
    },

}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#555',
    fontWeight: 400,
    fontSize: '0.875rem',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        // transition: theme.transitions.create('width'),
        transition: 'none',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '220px',
            // '&:focus': {
            //     width: '25ch',
            // },
        },
    },
}));




const SearchTopNavBar = () => {
    const [searchValue, setSearch] = useState('');

    // const auth = useContext(AuthContext);




    //? START - popper props and funcs
    const [popperAnchorEl, setPopperAnchorEl] = useState(null);
    const closeSearchPopper = () => { setPopperAnchorEl(null); };
    const openSearchPopper = (event) => { setPopperAnchorEl(event.currentTarget); };
    const popperOpen = Boolean(popperAnchorEl);
    //? END - popper props and funcs


    return (
        <ClickAwayListener onClickAway={closeSearchPopper}>
            <Box>
                <Search sx={{
                    ...popperOpen && {
                        borderRadius: '0.375rem 0.375rem 0rem 0rem',
                    }
                }} onClick={openSearchPopper}>
                    <SearchIconWrapper>
                        <SearchIcon sx={{ color: '#555555' }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                        type='search'
                        placeholder="Search"
                        inputProps={{ 'aria-label': 'search' }}
                        // action on change
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}


                    />
                </Search>

                <Popper style={{ zIndex: 2000 }} open={popperOpen} anchorEl={popperAnchorEl}>
                    <PaperSearchTopNavbar
                        closeSearchPopper={closeSearchPopper}
                        searchValue={searchValue}
                    />
                </Popper>
            </Box>
        </ClickAwayListener>
    );
}



export default SearchTopNavBar;