

import customAxios from "../../util/axios";
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';



export function useGetConversations() {

    const queryFn = async () => {
        const response = await customAxios({
            method: 'get',
            url: `/api/conversation/getConversations`
        })
        return response.data;
    }

    return useQuery(
        {
            queryKey: ['conversation'],
            queryFn,
            retryOnNetworkError: true,
            refetchOnWindowFocus: false,
            retry: 1,
        }
    );
}


export function useCreateConversation() {
    const queryClient = useQueryClient();

    const mutationFn = async (data) => {

        const response = await customAxios({
            method: 'post',
            url: `/api/conversation/createConversation`,
            data: JSON.stringify(data)
        })
        return response.data;
    }

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries('conversation')
        }
    });
}


export function useDeleteConversation() {
    const queryClient = useQueryClient();

    const mutationFn = async ({ conversationId }) => {

        const response = await customAxios({
            method: 'delete',
            url: `/api/conversation/deleteConversation/${conversationId}`
        })
        return response.data;
    }

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries('conversation')
        }
    });
}


export function useMarkConversationAsSeen() {

    const mutationFn = async ({ conversationId }) => {

        const response = await customAxios({
            method: 'post',
            url: `/api/conversation/markConversationAsSeen/${conversationId}`
        })
        return response.data;
    }

    return useMutation({
        mutationFn,
        onSuccess: () => {
            // queryClient.invalidateQueries('conversation')
        }
    });
}

