import { lazy } from 'react';
import Loadable from './loadable';
import MainLayout from '../layouts/MainLayout';
import PageNotFound from '../pages/PageNotFound';
import ProtectImmigrationFirmRoutes from './protected/ProtectImmigrationFirmRoutes';


const PageAddJobPosting = Loadable(lazy(() => import('../pages/ImmigrationFirm/PageAddJobPosting/PageAddJobPosting')));
const PageHomeImmigrationFirm = Loadable(lazy(() => import('../pages/ImmigrationFirm/Home/PageHomeImmigrationFirm')));
const PageProfileImmigrationFirm = Loadable(lazy(() => import('../pages/ImmigrationFirm/PageProfileImmigrationFirm/PageProfileImmigrationFirm')));
const PageManageJobs = Loadable(lazy(() => import('../pages/ImmigrationFirm/PageManageJobs/PageManageJobs')));
const PageViewJobDetails = Loadable(lazy(() => import('../pages/ImmigrationFirm/PageViewJobDetails/PageViewJobDetails')));
const PageSearchJobSeekers = Loadable(lazy(() => import('../pages/ImmigrationFirm/PageSearchJobSeekers/PageSearchJobSeekers')));
const PageViewJobSeekerProfile = Loadable(lazy(() => import('../pages/ImmigrationFirm/PageViewJobSeekerProfile/PageViewJobSeekerProfile')));
const PageInviteJobSeeker = Loadable(lazy(() => import('../pages/ImmigrationFirm/PageInviteJobSeeker/PageInviteJobSeeker')));
const PageViewImmigrationProfile = Loadable(lazy(() => import('../pages/ImmigrationFirm/PageViewImmigrationProfile/PageViewImmigrationProfile')));
const PageManageAccount = Loadable(lazy(() => import('../pages/PageManageAccount')));
const PageImmigrationFirmSubscriptionPlans = Loadable(lazy(() => import('../pages/ImmigrationFirm/SubscriptionPlans/PageImmigrationFirmSubscriptionPlans')));

const PageFaqs = Loadable(lazy(() => import('../pages/Faqs/PageFaqs')));
const PageBlogs = Loadable(lazy(() => import('../pages/Blogs/PageBlogs/PageBlogs')));
const PageViewBlog = Loadable(lazy(() => import('../pages/Blogs/PageViewBlog/PageViewBlog')));
const PageResources = Loadable(lazy(() => import('../pages/Resources/PageResources/PageResources')));
const PageViewResource = Loadable(lazy(() => import('../pages/Resources/PageViewResource/PageViewResource')));
const PageCart = Loadable(lazy(() => import('../pages/Cart/PageCart')));
const PageSubscription = Loadable(lazy(() => import('../pages/Subscription/PageSubscription')));


const ImmigrationFirmRoutes = {
    path: '/',
    element: (
        <ProtectImmigrationFirmRoutes>
            <MainLayout />
        </ProtectImmigrationFirmRoutes>
    ),
    children: [
        {
            path: '/immigration-firm',
            element: <PageHomeImmigrationFirm />
        },
        {
            path: '/immigration-firm/jobs/manage',
            element: <PageManageJobs />
        },
        {
            path: '/immigration-firm/profile',
            element: <PageProfileImmigrationFirm />
        },
        {
            path: '/immigration-firm/jobs/new',
            element: <PageAddJobPosting />
        },
        {
            path: '/immigration-firm/jobs/edit/:jobId',
            element: <PageAddJobPosting />
        },
        {
            path: '/immigration-firm/jobs/view/:jobId',
            element: <PageViewJobDetails />
        },
        {
            path: '/immigration-firm/search-job-seekers',
            element: <PageSearchJobSeekers />
        },
        {
            path: '/immigration-firm/view-job-seeker/:id',
            element: <PageViewJobSeekerProfile />
        },
        {
            path: '/immigration-firm/invite-job-seeker/:jobSeekerId',
            element: <PageInviteJobSeeker />
        },
        {
            path: '/immigration-firm/view-immigration-profile/:id',
            element: <PageViewImmigrationProfile />
        },
        {
            path: '/immigration-firm/manage-account',
            element: <PageManageAccount />
        },
        {
            path: '/immigration-firm/subscription-plans',
            element: <PageImmigrationFirmSubscriptionPlans />
        },
        {
            path: '/immigration-firm/faqs',
            element: <PageFaqs />
        },
        {
            path: '/immigration-firm/blogs',
            element: <PageBlogs />
        },
        {
            path: '/immigration-firm/blogs/:id',
            element: <PageViewBlog />
        },
        {
            path: '/immigration-firm/resources',
            element: <PageResources />
        },
        {
            path: '/immigration-firm/resources/:id',
            element: <PageViewResource />
        },
        {
            path: '/immigration-firm/subscription',
            element: <PageSubscription />
        },
        {
            path: '/immigration-firm/cart',
            element: <PageCart />
        },
        {
            path: '/*',
            element: <PageNotFound />
        },
    ]
};

export default ImmigrationFirmRoutes;
