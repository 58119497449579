import { useMutation } from "@tanstack/react-query";
import customAxios from "../../util/axios";

// type can either be 'job' or 'jobSeeker'
// ! ONLY JOB/JOBSEEKER
export function useAbuseReport() {
  async function postAbuseReport(data) {
    let config = {
      method: "post",
      url: `/api/abuseReports/`,
      data: JSON.stringify(data),
    };

    const response = await customAxios(config);
    return response.data;
  }

  return useMutation({
    mutationFn: (data) => postAbuseReport(data), // type can either be 'job' or 'jobSeeker'
  });
}

export function useSendContactQuery() {
  return useMutation({
    mutationFn: async (data) => {
      const response = await customAxios({
        method: "post",
        url: `/api/contact`,
        data: JSON.stringify(data),
      });
      return response.data;
    },
  });
}

// report in general
export function useGeneralReport() {
  async function postAbuseReport(data) {
    let config = {
      method: "post",
      url: `/api/abuseReports/general`,
      data: JSON.stringify(data),
    };

    const response = await customAxios(config);
    return response.data;
  }

  return useMutation({
    mutationFn: (data) => postAbuseReport(data), // type can either be 'job' or 'jobSeeker'
  });
}
