import React, { useEffect } from "react";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { Box, Grid, Typography, Stack } from "@mui/material";
import { useState } from "react";
import DarkButton from "../DarkButton";
import EmailIcon from "@mui/icons-material/Email";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import contactImg from "../../assets/images/contact-form/image.png";
import { RenderSocialMediaLinks, SocialMedialLinks } from "../Footer";
import InputField from "../../components/ui/InputField";
import Textarea from "../../components/ui/Textarea";
import HelperText from "../../components/ui/FormHelperText";
import CountrySelect from "../../components/ui/CountrySelect";
import Phone from "../../components/ui/Phone/Phone";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSendContactQuery } from "../../store/api/generalApi";
import CustomModal from "../Modals/CustomModal";
const contactImgStyles = {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    // borderRadius: "5px",
    margin: "auto",
    overflow: "hidden",
    padding: "10px"

};

const borderCorner = {
    position: "absolute",
    height: "50px",
    width: "50px",
    border: "1px solid #FFF",
}

const Schema = yup.object().shape({
    name: yup
        .string()
        .min(2)
        .max(30)
        .matches(/^[a-zA-Z\s-]+$/, "Name should be valid")
        .required("Name is required"),

    email: yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: yup
        .string()
        .nullable(true)
        .test("phone-number", "Phone number is not valid", (value) => {
            // if (!value) return true;
            return isValidPhoneNumber(value || "");
        }),
    company: yup.string().required("Company is required"),
    country: yup.string().required("Country is required"),
    message: yup.string().required("Message is required"),
});

const ContactFormModal = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(Schema),
        defaultValues: {
            name: "",
            email: "",
            phoneNumber: "",
            company: "",
            country: "",
            message: "",
        },
    });
    const [open, setOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const { mutateAsync } = useSendContactQuery();
    useEffect(() => {
        if (searchParams.get("contact") === "open") {
            setOpen(true);
        }
    }, [searchParams]);
    useEffect(() => {
        if (open === false) {
            searchParams.delete("contact");
            reset();
            setSearchParams(searchParams);
        }
    }, [open]);

    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = async (data) => {
        console.log(data);
        setSending(true);
        try {
            await mutateAsync({ ...data });
            enqueueSnackbar("Your query submitted", { variant: "success" });
            setOpen(false);
            reset();
        } catch (error) {
            enqueueSnackbar(
                error?.response?.data?.message || "Something went wrong!",
                { variant: "error" }
            );
        } finally {
            setSending(false);
            // setOpen(false);
            // reset()
        }
    };

    return (
        <CustomModal rounded open={open} setOpen={setOpen} closeIconColor={{ xs: "white", md: "black" }} paperStyles={{
            maxWidth: "1100px",
            p: 0,
            '& .MuiDialogContent-root': {
                padding: 0,
            },

        }}
            closeIconStyles={{
                '&:hover': { backgroundColor: { xs: "#6D6E76", md: '#F6F6F6' } }
            }}
        >
            <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                <Box
                    width={{ xs: "100%", lg: "45%" }}
                    sx={{
                        color: "white",
                        backgroundColor: "#1D1D1D",
                        p: "5rem 1.5rem",
                        position: "relative",
                        overflow: "hidden"
                    }}
                >

                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: " 1.25rem",
                            color: "#FFF",
                            marginBottom: 1,
                        }}
                    >
                        Transform your career!
                    </Typography>
                    <Typography
                        sx={{ fontWeight: 300, fontSize: " 0.8rem", color: "#FFF" }}
                    >
                        Open doors to your future career by dropping us a line!
                    </Typography>
                    <Box sx={{ mt: 3, mb: 3, position: "relative", }}>
                        <div
                            style={{
                                ...borderCorner,
                                top: 0,
                                left: 0,
                                borderTopLeftRadius: "4px",
                                borderRight: "none",
                                borderBottom: "none",
                            }}
                        ></div>

                        <img
                            src={contactImg}
                            lazy="true"
                            alt={"title"}
                            style={{
                                ...contactImgStyles,
                            }}
                        />
                        <div
                            style={{
                                ...borderCorner,
                                bottom: 6,
                                right: 0,
                                borderBottomRightRadius: "4px",
                                borderLeft: "none",
                                borderTop: "none",
                            }}
                        ></div>
                    </Box>
                    <Typography
                        sx={{ fontWeight: 300, fontSize: " 0.8rem", color: "#FFF" }}
                    >
                        You can reach us anytime via :
                    </Typography>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={1}
                        sx={{ mt: 2, mb: 2 }}
                        alignItems={{ xs: "left", sm: "center" }}
                        justifyContent={"space-around"}
                    >
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <PhoneInTalkIcon fontSize="small" />
                            <Typography
                                sx={{ fontWeight: 300, fontSize: " 0.85rem", color: "#FFF" }}
                            >
                                +1 (647) 812-4748
                            </Typography>
                        </Stack>
                        <Typography
                            sx={{ fontWeight: 300, fontSize: " 0.9rem", color: "#FFF", display: { xs: "none", sm: "block" } }}
                        >
                            |
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <EmailIcon fontSize="small" />
                            <Typography
                                sx={{ fontWeight: 300, fontSize: " 0.85rem", color: "#FFF" }}
                            >
                                contact@hiremai.com
                            </Typography>
                        </Stack>
                    </Stack>
                    <RenderSocialMediaLinks links={SocialMedialLinks} />
                    <div
                        style={{
                            position: "absolute",
                            width: 70,
                            height: 70,
                            right: 35,
                            bottom: 25,
                            background: "rgba(255, 249, 249, 0.13)",
                            transform: "matrix(0.97, -0.24, -0.24, -0.97, 0, 0)",
                            zIndex: 20,
                            borderRadius: "50%"
                        }}
                    ></div>
                    <div
                        style={{
                            position: "absolute",
                            width: 150,
                            height: 150,
                            right: -50,
                            bottom: -75,
                            background: "rgba(255, 255, 255, 0.12)",
                            transform: "rotate(14.1deg)",
                            zIndex: 20,
                            borderRadius: "50%"
                        }}
                    ></div>
                </Box>
                <Box width={{ xs: "100%", lg: "55%" }} sx={{ p: " 1.5rem" }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={12} lg={6}>
                                <Box>
                                    <InputField
                                        labelVariant={"small"}
                                        label="Name"
                                        textFieldProps={{
                                            type: "text",
                                            placeholder: "Your name",
                                            ...register("name"),
                                        }}
                                    />
                                    {errors.name && (
                                        <HelperText>{errors.name.message}</HelperText>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} lg={6}>
                                <Box>
                                    <InputField
                                        labelVariant={"small"}
                                        label="Email"
                                        textFieldProps={{
                                            type: "text",
                                            placeholder: "Your email address",
                                            ...register("email"),
                                        }}
                                    />
                                    {errors.email && (
                                        <HelperText>{errors.email.message}</HelperText>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} lg={6}>
                                <Box>
                                    <Phone
                                        labelVariant={"small"}
                                        label="Phone Number"
                                        placeholder={"+1 (555) 000-000"}
                                        control={control}
                                        name={"phoneNumber"}
                                    />
                                    {errors.phoneNumber && (
                                        <HelperText>{errors.phoneNumber.message}</HelperText>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} lg={6}>
                                <Box>
                                    <InputField
                                        labelVariant={"small"}
                                        label="Company"
                                        textFieldProps={{
                                            type: "text",
                                            placeholder: "Company name",
                                            ...register("company"),
                                        }}
                                    />
                                    {errors.company && (
                                        <HelperText>{errors.company.message}</HelperText>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <CountrySelect
                                        name="country"
                                        label="Country"
                                        labelVariant={"small"}
                                        placeholder={"Select country"}
                                        selectControlProps={register("country")}
                                    />
                                    {errors.country && (
                                        <HelperText>{errors.country.message}</HelperText>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <Textarea
                                        rows={10}
                                        labelVariant={"small"}
                                        label="How can we help?"
                                        textFieldProps={{
                                            type: "text",
                                            placeholder: "Tell us your query here...",
                                            ...register("message"),
                                            maxLength: 300,
                                        }}
                                    />
                                    {errors.message && (
                                        <HelperText>{errors.message.message}</HelperText>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <DarkButton
                                    disabled={sending}
                                    disableRipple
                                    type="submit"
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    {sending ? "Sending..." : "Get Started"}
                                </DarkButton>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Stack>
        </CustomModal >
    );
};

export default ContactFormModal;
