import customAxios from "../../util/axios";
import { useQuery } from '@tanstack/react-query';

export function useGetSearchResults({ q }) {

    const queryFn = async () => {
        const response = await customAxios({
            method: 'get',
            url: `/api/search/?q=${q}`
        })
        return response.data;
    }

    return useQuery(
        {
            queryKey: ['search', `search-${q}`],
            queryFn,
            enabled: false,
            refetchOnWindowFocus: false,
            retry: 0,
        }
    );
};