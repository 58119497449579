import { lazy } from "react";
import Loadable from "./loadable";
import MainLayout from "../layouts/MainLayout";
import PageNotFound from "../pages/PageNotFound";
import ProtectLoginPages from "./protected/ProtectLoginPage";

const Home = Loadable(lazy(() => import("../pages/Home/Home")));
const PageLogout = Loadable(lazy(() => import("../pages/Auth/PageLogout")));
const PageCart = Loadable(lazy(() => import("../pages/Cart/PageCart")));
const PageSubscription = Loadable(
  lazy(() => import("../pages/Subscription/PageSubscription"))
);
const PagePaymentAndRefundPolicy = Loadable(
  lazy(() => import("../pages/Cart/PagePaymentAndRefundPolicy"))
);
const PageResources = Loadable(
  lazy(() => import("../pages/Resources/PageResources/PageResources"))
);
const PageBlogs = Loadable(
  lazy(() => import("../pages/Blogs/PageBlogs/PageBlogs"))
);
const PageAboutUs = Loadable(lazy(() => import("../pages/AboutUs")));
const PageApplicantViewBlog = Loadable(
  lazy(() => import("../pages/Blogs/PageViewBlog/PageViewBlog"))
);
const PageTermsOfService = Loadable(
  lazy(() => import("../pages/GeneralPages/TermsOfService/TermsOfService"))
);
const PageFaqs = Loadable(lazy(() => import("../pages/Faqs/PageFaqs")));
const PageImmigrationFirmSubscriptionPlans = Loadable(
  lazy(() =>
    import(
      "../pages/ImmigrationFirm/SubscriptionPlans/PageImmigrationFirmSubscriptionPlans"
    )
  )
);
const PageApplicantSubscriptionPlans = Loadable(
  lazy(() =>
    import(
      "../pages/Applicant/SubscriptionPlans/PageApplicantSubscriptionPlans"
    )
  )
);

const GeneralRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/resources/courses",
      element: <PageResources />,
    },
    {
      path: "/resources/blogs",
      element: <PageBlogs />,
    },
    {
      path: "/resources/blogs/:id",
      element: <PageApplicantViewBlog />,
    },
    {
      path: "/resources/faqs",
      element: <PageFaqs />,
    },
    {
      path: "/terms-of-service",
      element: <PageTermsOfService />,
    },
    {
      path: "/about-us",
      element: <PageAboutUs />,
    },
    {
      path: "/logout",
      element: <PageLogout />,
    },
    {
      path: "/subscription-plans/immigration-firm",
      element: <PageImmigrationFirmSubscriptionPlans />,
    },
    {
      path: "/subscription-plans/applicant",
      element: <PageApplicantSubscriptionPlans />,
    },
    {
      path: "/cart",
      element: (
        <ProtectLoginPages>
          <PageCart />
        </ProtectLoginPages>
      ),
    },
    {
      path: "/subscription",
      element: (
        <ProtectLoginPages>
          <PageSubscription />
        </ProtectLoginPages>
      ),
    },
    {
      path: "/payment-and-refund-policy",
      element: <PagePaymentAndRefundPolicy />,
    },
    {
      path: "/*",
      element: <PageNotFound />,
    },
  ],
};

export default GeneralRoutes;
